export function initPictures(pictures) {
  return (dispatch) => {
    dispatch({
      type: 'SET_POPUP_PICTURES',
      payload: pictures
    })
  }
}

export function setOpenedModals(openedModals) {
  return (dispatch) => {
    dispatch({
      type: 'SET_OPENED_IMAGE_MODAL',
      payload: openedModals
    })
  }
}
