import * as constants from './constants'
import axiosInstance from 'configurations/axios_instance'
import { REALTIME_REPORT_INDEX_PATH } from 'configurations/api_end_points'

export function getRealtimeReportData(stationId) {
  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.get(REALTIME_REPORT_INDEX_PATH,
      {params: { station_id: stationId }
    }).then(response => {
      dispatch({
        type: constants.GET_STATION_REALTIME_REPORT_SUCCESS,
        payload: response.data
      })
    }).catch().then(() => dispatch({type: 'UNBLOCK_UI'}))
  }
}