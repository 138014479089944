import React, { Component } from 'react'
import classNames from 'classnames'

import './styles.sass'

class ScrolltopButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      intervalId: 0,
      isBottom: false
    };
    this.handleScroll = this.handleScroll.bind(this)
    this.isBottom = this.isBottom.bind(this)
  }

  isBottom(el) {
    const headerTop = document.getElementsByClassName('header-top')[0];
    const topNavigation = document.getElementsByClassName('top-navigation')[0];

    let headerTopHeight = headerTop.getBoundingClientRect().height
    let topNavigationHeight = topNavigation.getBoundingClientRect().height

    return el.getBoundingClientRect().bottom <= (window.innerHeight - (headerTopHeight + topNavigationHeight));
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    const wrappedElement = document.getElementsByClassName('content')[0];
    this.setState({pageYOffset: window.pageYOffset, isBottom: this.isBottom(wrappedElement)})
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  render() {
    const classnames = classNames(
      { 'scrolltop-button-relative': this.state.isBottom},
      { 'd-none': window.pageYOffset <= 150 },
      { 'flex-row-reverse bd-highlight scrolltop-button': window.pageYOffset > 150 }
    )

    return(
      <div className={ classnames } onClick={() => { this.scrollToTop() }}>
        <button className="btn btn-primary mr-3">
          <img src={require('assets/images/scrolltop-icon.png')}/>
        </button>
      </div>
    )
  }
}

ScrolltopButton.defaultProps = {
  scrollStepInPx: "50",
  delayInMs: "5"
}

export default ScrolltopButton
