import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from "react-router-dom"
import { Button } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import {
  getListOfStations,
  getNoticeData,
  saveSurveyAnswer,
  getListOfAirports
} from './actions'

import Map from './components/map'
import Filter from './components/filter'
import Notification from './components/notification'
import Survey from './components/survey'

import './styles.sass'

class HomeNonLogin extends Component {
  constructor(props) {
    super(props)
    this.changeSurveyAnswer = this.changeSurveyAnswer.bind(this)

    this.state = {
      is_useful: true
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getListOfStations()
    this.props.getListOfAirports()
    this.props.getNoticeData()
  }

  changeSurveyAnswer(is_useful) {
    this.setState({is_useful: is_useful})
  }

  render() {
    const { stationsData, airportsData, notification, currentUser, router, savedSurvey } = this.props

    return(
      <React.Fragment>
        {
          !!notification && !!notification.noticetext && (
            <Notification notification={notification}/>
          )
        }

        <div className="content-full-width">
          <Filter
            stationsData={stationsData}
            airportsData={airportsData}
            airportsData={airportsData}
            currentUser={currentUser}
            loggedIn={false}
            router={router}
          />

          <Map
            loggedIn={false}
            currentUser={currentUser}
            stationsData={stationsData}
            airportsData={airportsData}
          />
        </div>

        <Survey is_useful={this.state.is_useful}
                savedSurvey={savedSurvey}
                submitSurveyAnswer={() => this.props.saveSurveyAnswer(this.state.is_useful)}
                changeSurveyAnswer={this.changeSurveyAnswer}
        />

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    airportsData: state.airportsData,
    stationsData: state.stationsData,
    notification: state.notificationData,
    currentUser: state.currentUser,
    router: state.router,
    savedSurvey: state.savedSurvey,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getListOfStations,
    getNoticeData,
    saveSurveyAnswer,
    getListOfAirports
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeNonLogin)
