import React, { Component } from 'react'
import { remove, replace } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  initPictures,
  setOpenedModals
} from './actions'
import './styles.sass'

class ImageModals extends Component {
  constructor(props) {
    super(props)
    this.setModalImageShow = this.setModalImageShow.bind(this)
  }

  setModalImageShow(index) {
    let { openedModals } = this.props
    openedModals.push(index)
    this.props.setOpenedModals(openedModals)
  }

  closeModal(modalIndex) {
    let { openedModals } = this.props

    remove(openedModals, (n)=> {
      return n === modalIndex
    })

    this.props.setOpenedModals(openedModals)
  }

  render() {
    const { popupPictures, openedModals } = this.props

    return(
      <div className={`modal-wrapper ${openedModals.length > 0 ? 'visible' : ''}`}>
        <div className="image-modals-container">
          {
            popupPictures.map((pic, index) => {
              if (openedModals.indexOf(index) > -1) {
                return(
                  <section className="modal-window" key={index}>
                    <header className="image-modals-header">
                      <button
                        type="button" onClick={() => this.closeModal(index)}
                        className="close-modal-button"
                        aria-label="Close modal window"
                      >
                        X
                      </button>
                    </header>
                    <img src={replace(pic, 'storage.cloud.google.com', 'storage.googleapis.com')} style={{maxWidth: '100%'}}/>
                  </section>
                )
              }
            })
          }
        </div>

        <div className="thumb-images">
            {
              popupPictures.map((pic, index) => {
                if (!!pic) {
                  return(
                    <div className='box-container' key={index}>
                      <img onClick={() => this.setModalImageShow(index)} className='thumbnail' src={replace(pic, 'storage.cloud.google.com', 'storage.googleapis.com')} />
                    </div>
                  )
                }
              })
            }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    popupPictures: state.popupPictures,
    openedModals: state.openedModals,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setOpenedModals
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageModals)
