import cookie from 'react-cookies'
import * as constants from 'app/sign-in/constants'
import { extend } from 'lodash'
import { setAuthorizationToken } from 'configurations/axios_instance'
const initialStateCurrentUser = {
}

export function currentUserReducer(state=initialStateCurrentUser, action) {
  switch (action.type) {
    case constants.LOGIN_SUCCESS:
      const expires = new Date()
      expires.setDate(Date.now() + 1000 * 60 * 60)
      cookie.save(
        'X-API-TOKEN',
        action.payload.authentication_token, { path: '/', expires }
      )
      setAuthorizationToken(action.payload.authentication_token)
      return extend({}, state, action.payload)
    case constants.LOGOUT_SUCCESS:
      cookie.remove('X-API-TOKEN', { path: '/' })
      return {}
    default:
      return state
  }
}