export function blockUIReducer(state=false, action) {
  switch (action.type) {
    case 'BLOCK_UI':
      return true
    case 'UNBLOCK_UI':
      return false
    default:
      return state
  }
}
