import React, { Component } from 'react'
import { CSVLink } from "react-csv"

class CustomReactCsv extends CSVLink {
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const { data, headers, separator, uFEFF } = this.props;
      this.setState({ href: this.buildURI(data, uFEFF, headers, separator) });
    }
  }
}

export default CustomReactCsv
