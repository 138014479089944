import React, { Component } from 'react'
import { Table, Button } from 'react-bootstrap'

class RightSideButtons extends Component {
  render() {
    const { tableBodyRows, toggleSound, goToDailyPage } = this.props

    return(
      <Table hover size="sm" className="collapse-table-right-side">
        <tbody>
          <tr><td className="invisible">.</td></tr>
          <tr><td className="invisible">.</td></tr>
          {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
            <tr key={index} className={`${index % 2 !== 0 ? 'dark-row' : 'light-row'}`}>
              <td className={index===0 ? 'border-radius-top-right' : ''}>
                <Button className="btn-show-playlist" onClick={() => goToDailyPage(row.day, row.month, row.year)}>
                  詳細
                  <span className='arrow-right btn-arrow'> </span>
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )
  }
}

export default RightSideButtons

