import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap'

class Notification extends Component {
  render() {
    const { notification } = this.props
    return (
      <div className="top-notification">
        <span className="notification-button">お知らせ</span>
        <span className='notification-date'>{notification.datecreated}</span>
        <span>{notification.noticetext}</span>
      </div>
    );
  }
}

export default Notification