import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Accordion, Card, Table } from 'react-bootstrap'
import { getDailyData } from './actions'
import { push } from 'connected-react-router'
import { cloneDeep, find } from 'lodash'
import qs from 'query-string'
import moment from 'moment'

import Collapse from 'app/daily/components/collapses'
import { getListOfStations } from '../home/actions'
import SignedInNav from 'components/signed_in_nav'
import Pagination from 'components/pagination'
import ImageModals from 'app/image-modals'

import './styles.sass'

class Daily extends Component {
  constructor(props) {
    super(props)
    this.reloadDataFor = this.reloadDataFor.bind(this)
    this.buildCsvData = this.buildCsvData.bind(this)
    this.changeCollapeOfDay = this.changeCollapeOfDay.bind(this)
    this.onGoToPrevPage = this.onGoToPrevPage.bind(this)
    this.onGoToNextPage = this.onGoToNextPage.bind(this)
    this.prevPageEnabled = this.prevPageEnabled.bind(this)
    this.nextPageEnabled = this.nextPageEnabled.bind(this)
    this.prevPageText = this.prevPageText.bind(this)
    this.nextPageText = this.nextPageText.bind(this)
    this.prevMonth = this.prevMonth.bind(this)
    this.nextMonth = this.nextMonth.bind(this)
    this.state = {
      activeKey: '0',
      month: '',
      day: ''
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getListOfStations()

    const { router: { location: { query: { station_id, day, year, month }}}} = this.props

    if (!station_id) { return this.props.push({pathname: '/'}) }

    if (!month || !year || !day ) {
      const month = (new Date()).getMonth()+1
      const year = moment().format('YYYY')
      const day = 1
      const query = { station_id: station_id, year: year, month: month, day: day }

      this.setState({activeKey: '0', day: 1, month: month})
      this.props.push({pathname: '/daily', search: qs.stringify(query) })
    } else {
      this.setState({activeKey: `${parseInt(day)-1}`, day: day, month: month})
    }

    this.props.getDailyData(day, month, year, station_id)
  }

  reloadDataFor(stationId) {
    const { router: { location: { query: { day, year, month }} } } = this.props

    const query = { station_id: stationId, year: year, day: day, month: month };
    this.props.push({pathname: '/daily', search: qs.stringify(query) })
    this.props.getDailyData(day, year, month, stationId)
  }

  changeCollapeOfDay(day, month, year, eventKey) {
    const { router: { location: { query: { station_id } } } } = this.props
    this.setState({
      activeKey: eventKey,
      day: day,
      month: month
    }, () => {
      this.props.getDailyData(this.state.day, this.state.month, year, station_id)
      const query = { station_id: station_id, year: year, month: this.state.month, day: this.state.day }
      this.props.push({pathname: '/daily', search: qs.stringify(query) })
    })
  }

  buildCsvData() {
    let csvData = []
    const dailyData = cloneDeep(this.props.dailyData)

    let data = dailyData.collection[parseInt(this.state.activeKey)]

    if (!!data) {
      csvData.push([data.collapseHeaderText]) // Append the year to each section
    }

    // Header of CSV
    let tableHeadColumns = cloneDeep(dailyData.tableHeadColumns)
    tableHeadColumns.unshift('')
    csvData.push(tableHeadColumns)
    // finish render header for a section

    // Adding daily data to csv
    if (!!data) {
      data.tableBodyRows.forEach((rowData) => {

        let row = [
          rowData.header,
          rowData.data['lasmax'],
          rowData.data['duration'],
          rowData.data['bgnlevel'],
          rowData.data['torl'],
          rowData.data['id'],
          rowData.data['altitude'],
          rowData.data['lae'],
          rowData.data['laeq'],
          rowData.data['estimation']
        ]
        csvData.push(row)
      })
    }
    // Finished adding daily data to csv

    return csvData
  }

  ///////////////////// PAGINATION STUFF /////////////////
  prevPageEnabled(){
    let { router: { location: { query: { year }} } } = this.props

    if (parseInt(year) <= 2010) {
      return false
    }

    return true
  }

  nextPageEnabled(){
    let { router: { location: { query: { year, month }} } } = this.props

    if (!year || !month || (parseInt(year) >= moment().year() && parseInt(month) >= moment().month()+1) ) {
      return false
    }
    return true
  }

  prevPageText() {
    let { router: { location: { query: { year }} } } = this.props

    if (this.prevMonth() == 12) {
      year = parseInt(year) - 1
    }
    return `${year}年${this.prevMonth()}月の日報へ`
  }

  nextPageText() {
    let { router: { location: { query: { year }} } } = this.props

    if (this.nextMonth() == 1) {
      year = parseInt(year) + 1
    }
    return `${year}年${this.nextMonth()}月の日報へ`
  }

  onGoToPrevPage() {
    let { router: { location: { query: { year, station_id }} } } = this.props

    if (this.prevMonth() == 12) {
      year = parseInt(year) - 1
    }

    let query = { station_id: station_id, year: year, month: this.prevMonth(), day: 1 }
    window.location.href = "/daily?" + qs.stringify(query)
  }

  onGoToNextPage() {
    let { router: { location: { query: { year, station_id }} } } = this.props

    if (this.nextMonth() == 1) {
      year = parseInt(year) + 1
    }

    let query = { station_id: station_id, year: year, month: this.nextMonth(), day: 1 }
    window.location.href = "/daily?" + qs.stringify(query)
  }

  prevMonth() {
    let { router: { location: { query: { month }} } } = this.props

    let prevMonth = null
    if (parseInt(month) <= 1) {
      prevMonth = 12
    } else {
      prevMonth = parseInt(month) - 1
    }

    return prevMonth
  }

  nextMonth() {
    let { router: { location: { query: { month }} } } = this.props

    let nextMonth = null
    if (parseInt(month) >= 12) {
      nextMonth = 1
    } else {
      nextMonth = parseInt(month) + 1
    }

    return nextMonth
  }
  ///////////////////// END PAGINATION STUFF /////////////////

  render() {
    const { activeKey } = this.state
    const { dailyData, stationsData } = this.props
    let { router: { location: { query: { year, month, day, station_id }} } } = this.props
    const station = find(stationsData.stations, {stationid: station_id})

    let dayObject = new Date(year, month-1, day)

    return(
      <React.Fragment>
        <SignedInNav
          {...this.props}
          stationsData={stationsData}
          reloadDataFor={this.reloadDataFor}
          activeLink="daily"
          stationId={station_id}
          csvData={this.buildCsvData()}
          year={year}
          month={month}
          day={day}
          fileName={`日報-${moment(dayObject).format('YYYYMMDD')}-${station_id}.csv`}
        />

        <div className="content daily-content">
          <div className="green-text-wrapper">
            <span className="green-text">
              {
                !!station && station.name
              }
            </span>
          </div>

          <div className="top-time-wrapper">
            <span className="top-time-text">
              {year}年{month}月 日報
            </span>
          </div>
          <Accordion defaultActiveKey={activeKey} activeKey={activeKey}>
            <div className="collapse-wrapper">
              {
                dailyData.collection.map((data, index) => {
                  return(<Collapse
                    collapseHeaderText={data.collapseHeaderText}
                    eventKey={`${index}`}
                    key={index}
                    tableBodyRows={data.tableBodyRows}
                    tableHeadColumns={data.tableHeadColumns}
                    changeCollapeOfDay={this.changeCollapeOfDay}
                    year={data.year}
                    month={data.month}
                    day={data.day}
                  />)
                })
              }

            </div>
          </Accordion>
        </div>

        <Pagination
          page="monthly"
          prevPageEnabled={this.prevPageEnabled()}
          nextPageEnabled={this.nextPageEnabled()}
          prevPageText={this.prevPageText()}
          nextPageText={this.nextPageText()}
          onGoToPrevPage={this.onGoToPrevPage}
          onGoToNextPage={this.onGoToNextPage}
        />

        <ImageModals />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dailyData: state.dailyData,
    router: state.router,
    stationsData: state.stationsData
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getDailyData,
    getListOfStations,
    push
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Daily)
