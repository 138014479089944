import * as constants from './constants'
import axiosInstance from 'configurations/axios_instance'
import { SAVE_DOWNLOAD_CSV_PATH }from 'configurations/api_end_points'

export function saveDownLoadCsvLocation(csv_location) {
  return (dispatch) => {
    axiosInstance.post(SAVE_DOWNLOAD_CSV_PATH, { csv_location: csv_location }).then(response => {
      dispatch({
        type: constants.DOWNLOAD_CSV_IS_SAVED
      })
    })
  }
}