import * as constants from 'app/home/constants'
import { extend } from 'lodash'

const initialState = {
  stations: []
}

export function stationsDataReducer(state=initialState, action) {
  switch (action.type) {
    case constants.GET_STATION_DATA_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export function airportsDataReducer(state={ airports: [] }, action) {
  switch (action.type) {
    case constants.GET_LIST_OF_AIRPORTS_SUCCESS:
      return action.payload
    default:
      return state
  }
}


const initialNotice = {

}

export function noticeDataReducer(state=initialNotice, action) {
  switch (action.type) {
    case constants.GET_NOTICE_DATA_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export function savedSurveyReducer(state=false, action) {
  switch (action.type) {
    case constants.SURVEY_IS_SAVED:
      return true
    default:
      return state
  }
}