import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { assignIn } from 'lodash'

import Thead from 'app/daily/components/tables/thead'
import ContentRow from 'app/daily/components/tables/content_row'
import LeftSideHeader from 'app/daily/components/tables/left_side_header'
import RightSideButtons from 'app/daily/components/tables/right_side_buttons'

class DailyTable extends Component {
  constructor(props) {
    super(props)
    this.toggleSound = this.toggleSound.bind(this)
    this.updateTableBodyRowsState = this.updateTableBodyRowsState.bind(this)

    this.state = { tableBodyRows: [] }
  }

  UNSAFE_componentWillMount() {
    this.updateTableBodyRowsState(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateTableBodyRowsState(nextProps)
  }

  updateTableBodyRowsState(props) {

    let tableBodyRows = props.tableBodyRows.map((row) => {
      return assignIn(row, {
        isShowingSound: false,
        elementHeightOnSoundVisible: 0 // once user clicked on the button to disable sound, we have to update the left header for UI
      })
    })

    this.setState({tableBodyRows: tableBodyRows})
  }

  toggleSound(index) {
    this.state.tableBodyRows[index].isShowingSound = !this.state.tableBodyRows[index].isShowingSound
    this.setState({tableBodyRows: this.state.tableBodyRows}, () => {
      setTimeout(() => {
        if (this.state.tableBodyRows[index].isShowingSound) {
          let elHeight = document.getElementById(`row-sound-${index}`).clientHeight
          this.state.tableBodyRows[index].elementHeightOnSoundVisible = elHeight
        } else {
          this.state.tableBodyRows[index].elementHeightOnSoundVisible = 0
        }
        this.setState({tableBodyRows: this.state.tableBodyRows})
      }, 100)
    })
  }

  render() {
    const { tableHeadColumns } = this.props
    const { tableBodyRows } = this.state

    return(
      <React.Fragment>
        <div className="table-left-header">
          <LeftSideHeader tableBodyRows={tableBodyRows} />
        </div>

        <div className="table-content">
          <Table hover size="sm" className="collapse-table-content">

            <thead>
              <Thead
                tableHeadColumns={tableHeadColumns}
                borderLeftClassNames="border-radius-top-left"
                borderRightClassNames="border-radius-top-right"
              />
            </thead>

            <tbody>
              {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
                <ContentRow
                  rowData={row}
                  rowIndex={index}
                  numberOfRows={tableBodyRows.length}
                  key={index}
                  tableHeadColumns={tableHeadColumns}
                  toggleSound={this.toggleSound}
                />
              )}
            </tbody>

            <tfoot>
              <Thead
                tableHeadColumns={tableHeadColumns}
                borderLeftClassNames="border-radius-bottom-left footer-row"
                borderRightClassNames="border-radius-bottom-right footer-row"
              />
            </tfoot>

          </Table>
        </div>

        <div className="table-right-content">
          <RightSideButtons
            tableBodyRows={tableBodyRows}
            toggleSound={this.toggleSound}
          />
        </div>

      </React.Fragment>
    )
  }
}

export default DailyTable
