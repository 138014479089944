import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class GuestTopNav extends Component {
  constructor(props) {
    super(props)
    this.onFilterChange = this.onFilterChange.bind(this)
  }

  onFilterChange(e) {
    this.props.reloadDataFor(e.target.value)
  }

  render() {
    const {airportsData, stationsData, stationId, airportId} = this.props
    return(
      <Navbar collapseOnSelect expand="lg" className="top-navigation">
        <Nav className="mr-auto">
          <select className="header-select-1 form-control"
          onChange={this.onFilterChange}
          value={stationId || airportId}>
            {
              !!airportsData && !!airportsData.airports && airportsData.airports.map((airport, index) => {
                return(
                  <option
                    value= {airport.icao}
                    key={index}
                  >
                    { airport.name }
                  </option>
                )
              })
            }
            {
              !!stationsData && !!stationsData.stations && stationsData.stations.map((station, index) => {
                return(
                  <option
                    value= {station.stationid}
                    key={index}
                  >
                    { station.name }
                  </option>
                )
              })
            }
          </select>
        </Nav>

        <Nav>
          <Link
            className="root-sea-btn ml-2 text-blue pl-4 pr-4"
            to={{pathname: "/"}}
          >
            <img src={require('assets/images/arrow-left.png')}/>
            <span className="ml-3">
              トップへ戻る
            </span>
          </Link>
        </Nav>
      </Navbar>
    )
  }
}

export default GuestTopNav
