import cookie from 'react-cookies'

const initialState= {
  isShow: false,
  message: null
}

export function messageReducer(state=initialState, action) {
  switch (action.type) {
    case 'SHOW_NOTIF_MESSAGE':
      return { message: action.payload.message, isShow: true }
    case 'HIDE_NOTIF_MESSAGE':
      return initialState
    default:
      return state
  }
}

