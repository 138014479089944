import * as constants from 'app/daily/constants'
import { extend } from 'lodash'

const initialState = {
  collection: [],
  tableHeadColumns: []
}

export function dailyDataReducer(state=initialState, action) {
  switch (action.type) {
    case constants.GET_DAILY_DATA_SUCCESS:
      return action.payload
    default:
      return state
  }
}