import React, { Component } from 'react'
import moment from 'moment'
import "./styles.sass"
import Sound from 'react-sound'
import ReactSlider from 'react-slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { filter, remove, replace } from 'lodash'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  initPictures,
  setOpenedModals
} from 'app/image-modals/actions'

class AppSound extends Component {
  constructor(props) {
    super(props)
    this.handleSongLoading = this.handleSongLoading.bind(this)
    this.handleSongPlaying = this.handleSongPlaying.bind(this)
    this.handleSongFinishedPlaying = this.handleSongFinishedPlaying.bind(this)
    this.getMMSSFromMillis = this.getMMSSFromMillis.bind(this)
    this.renderThumbSlider = this.renderThumbSlider.bind(this)
    this.renderTrackSlider = this.renderTrackSlider.bind(this)
    this.onAfterSeekSliderValueChange = this.onAfterSeekSliderValueChange.bind(this)
    this.onBeforeSeekSliderValueChange = this.onBeforeSeekSliderValueChange.bind(this)
    this.onSeekSliderValueChange = this.onSeekSliderValueChange.bind(this)
    this.toggleAudioStatus = this.toggleAudioStatus.bind(this)
    this.initPicturesModalAndShow = this.initPicturesModalAndShow.bind(this)

    this.state = {
      playStatus: Sound.status.PAUSED,
      soundDuration: 0,
      soundPosition: 0,
      modalImageShow: false,
      imageURL: null,
      openedModals: [],
      pictures: []
    }
    this.isSeeking = false
    this.isStopping = false
    this.playStatus = this.state.playStatus
    this.pauseAudioTimeOut = null

  }

  onSeekSliderValueChange(value) {
    this.setState({soundPosition: value})
  }

  onBeforeSeekSliderValueChange() {
    this.isSeeking = true
    this.playStatus = this.state.playStatus;
    this.setState({playStatus: Sound.status.PAUSED})
  }

  onAfterSeekSliderValueChange(value) {
    this.setState({playStatus: this.playStatus, soundPosition: value}, () =>{
      setTimeout(() => {
        this.isSeeking = false
      }, 500)
    })
  }

  handleSongLoading(data) {
    this.isSeeking = true
    this.setState({
      soundDuration: data.durationEstimate,
      soundPosition: data.position
    }, () =>{
      setTimeout(() => {
        this.isSeeking = false
      }, 500)
    })
  }

  handleSongPlaying(data) {
    if (!this.isSeeking) {
      this.setState({
        soundPosition: data.position
      })
    }
  }

  handleSongFinishedPlaying() {

  }

  getSeekSliderPosition() {
    if (
      this.state.soundPosition !== null &&
      this.state.soundDuration !== null
    ) {
      return this.state.soundPosition
    }
    return 0;
  }

  getMMSSFromMillis(millis) {
    const totalSeconds = millis / 1000;
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor(totalSeconds / 60);

    const padWithZero = number => {
      const string = number.toString();
      if (number < 10) {
        return '0' + string
      }
      return string;
    };
    return padWithZero(minutes) + ':' + padWithZero(seconds)
  }


  toggleAudioStatus() {
    this.isSeeking = true;

    if (this.state.playStatus == Sound.status.PAUSED) {
      this.setState({playStatus: Sound.status.PLAYING})
    } else {
      this.setState({playStatus: Sound.status.PAUSED})
    }

    clearTimeout(this.pauseAudioTimeOut);
    this.pauseAudioTimeOut = setTimeout(() => {
      if (this.state.playStatus == Sound.status.PLAYING) {
        this.isSeeking = false;
      }
    }, 500)
  }

  renderTrackSlider(props, state) {
    return(
      <div {...props} />
    )
  }

  renderThumbSlider(props, state) {
    return(
      <img {...props} className='slider-ellipse' src={require('assets/images/ellipse.png')} />
    )
  }

  UNSAFE_componentWillMount() {
    const pictures = !!this.props.data['pic'] ? filter(this.props.data['pic'], (p) => {return !!p} ) : []
    // this.props.initPictures(pictures)
    this.setState({pictures: pictures})
  }

  initPicturesModalAndShow(index) {
    const pictures = !!this.props.data['pic'] ? filter(this.props.data['pic'], (p) => {return !!p} ) : []
    this.props.initPictures(pictures)
    let { openedModals } = this.props
    openedModals.push(index)
    this.props.setOpenedModals(openedModals)
  }

  render() {
    const { data, description, popupPictures, openedModals } = this.props
    const { soundDuration, soundPosition, playStatus } = this.state

    let showDescription

    if (!!description) {
      showDescription = <div className="text-right pb-3 panel-small-text">
                          { description }
                        </div>
    }

    return(
      <div className="sticky" id={`${this.props.id}`}>
        {
          !!data.wave && (
            <React.Fragment>
              { showDescription }

              <div className="sticky-row sound-wrapper d-flex">
                <span className="sticky-label sound-label">
                  音声
                </span>
                <div className="sticky-content music-player-wrapper">
                  <div onClick={this.toggleAudioStatus}>
                    {
                      playStatus == Sound.status.PLAYING ?
                        <FontAwesomeIcon icon={faPause} />
                      :
                        <FontAwesomeIcon icon={faPlay} />
                    }
                  </div>
                  <span className='sound-start-time time-label'>
                    {this.getMMSSFromMillis(soundPosition)}
                  </span>

                  <Sound
                    url={replace(data.wave, 'storage.cloud.google.com', 'storage.googleapis.com')}
                    playStatus={this.state.playStatus}
                    playFromPosition={soundPosition}
                    onLoading={this.handleSongLoading}
                    onPlaying={this.handleSongPlaying}
                    onFinishedPlaying={this.handleSongFinishedPlaying}
                    autoLoad={true}
                  />
                  <ReactSlider
                    className="sound-slider"
                    thumbClassName="sound-thumb"
                    trackClassName="sound-track"
                    min={0}
                    max={soundDuration}
                    value={this.getSeekSliderPosition()}
                    defaultValue={this.getSeekSliderPosition()}
                    renderTrack={this.renderTrackSlider}
                    renderThumb={this.renderThumbSlider}
                    onAfterChange={this.onAfterSeekSliderValueChange}
                    onChange={this.onSeekSliderValueChange}
                    onBeforeChange={this.onBeforeSeekSliderValueChange}
                  />

                  <span className='sound-end-time time-label'>
                    {this.getMMSSFromMillis(soundDuration)}
                  </span>
                  <img className='void-icon' src={require('assets/images/sound_icon.png')} />
                </div>
              </div>
            </React.Fragment>
          )
        }

        {
          (this.state.pictures.length > 0 ) && (
            <div className="sticky-row playlist d-flex">
              <span className="sticky-label">
                画像
              </span>

              <div className="box-wrapper sticky-content">
                {
                  this.state.pictures.map((pic, index) => {
                    if (!!pic) {
                      return(
                        <div className='box-container' key={index}>
                          <img onClick={() => this.initPicturesModalAndShow(index)} className='thumbnail' src={replace(pic, 'storage.cloud.google.com', 'storage.googleapis.com')} />
                        </div>
                      )
                    }
                  })
                }
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    popupPictures: state.popupPictures,
    openedModals: state.openedModals,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    initPictures,
    setOpenedModals
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSound)
