import React, { Component } from "react";
import Form from "react-bootstrap/Form";

class ContentRow extends Component {
  render() {
    const { rowData, rowIndex, onSelected, checkedRows } =
      this.props;
    // console.log(rowData.stationid, isChecked);

    return (
      <tr className={`${rowIndex % 2 !== 0 ? "dark-row" : "light-row"} `}>
        <th className="checkbox">
          <Form.Check
            type="checkbox"
            checked={!!checkedRows[rowData.stationid]}
            onChange={(e) => onSelected(e, rowData.stationid)}
          />
        </th>
        <td className="stationid font-weight-normal">{rowData.stationid}</td>
        <td className="name font-weight-normal">{rowData.name}</td>
      </tr>
    );
  }
}

export default ContentRow;
