import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import MonlthlyTable from '../tables'

class Collapse extends Component {
  constructor(props) {
    super(props)
    this.renderTable = this.renderTable.bind(this)
  }

  renderTable() {
    const { tableHeadColumns, tableBodyRows, goToDailyPage } = this.props

    return(
      <MonlthlyTable
        tableBodyRows={tableBodyRows}
        tableHeadColumns={tableHeadColumns}
        goToDailyPage={goToDailyPage}
      />
    )
  }

  renderEmptyMessage() {
    const { emptyMessage } = this.props

    return(
      <div className="text-center no-data-text panel-small-text">
        {!!emptyMessage ? emptyMessage : 'データはありません'}
      </div>
    )
  }

  render() {
    const {
      collapseHeaderText, eventKey, tableHeadColumns, activeKey,
      changeCollapeOfMonth, month, year
    } = this.props

    return(
      <React.Fragment>
        <Accordion.Toggle as={Card.Header}
          eventKey={eventKey}
          className="collapse-header clickable-collapse-header"
          onClick={() => changeCollapeOfMonth(month, year, eventKey)}>

          {collapseHeaderText}

          <div className="position-absolute text-right collapse-arrow-icon">
            {
              activeKey === eventKey ?
                <img src={require('assets/images/arrow-up.png')}/>
              : <img src={require('assets/images/arrow-down.png')}/>
            }
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={eventKey}>
          {
            (!!tableHeadColumns && tableHeadColumns.length > 0) ?

            (

              <React.Fragment>
                <div className="text-right panel-small-text pb-3">
                  表は横方向にもスクロールできます。
                </div>

                {this.renderTable()}
              </React.Fragment>

            ) : this.renderEmptyMessage()
          }
        </Accordion.Collapse>
        <div className="height-1-5rem"/>
      </React.Fragment>
    )
  }
}

export default Collapse
