import * as constants from 'app/sign-in/constants'
import axiosInstance from 'configurations/axios_instance'
import { SIGN_IN_PATH } from 'configurations/api_end_points'
import { Route, Redirect } from 'react-router-dom'
import { push } from 'connected-react-router'
import { showMessage } from 'app/message/actions'

export function loginUser(email, password) {

  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.post(SIGN_IN_PATH, {
      email: email,
      password: password
    }).then((response) => {
      dispatch({
        type: constants.LOGIN_SUCCESS,
        payload: response.data
      })

      window.location.href = '/'
    }, error => {
      dispatch(showMessage(error.response.data.errors))
    }).then(() => dispatch({type: 'UNBLOCK_UI'}))
  }
}

export function logoutUser() {

  return (dispatch) => {
    dispatch({
      type: constants.LOGOUT_SUCCESS,
    })
    window.location.href = '/'
  }

}
