import React, { Component } from 'react'

class Thead extends Component {
  constructor(props) {
    super(props)
    this.renderHeaderRow = this.renderHeaderRow.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { tableHeadColumns } = this.props

    this.numberOfColumn = 0

    tableHeadColumns.map((column) => {
      column.subHeaderTexts.map((text) => {
        this.numberOfColumn = this.numberOfColumn + 1
      })
    })
  }

  renderHeaderRow() {
    const { tableHeadColumns } = this.props

    return(
      <tr>
        {tableHeadColumns.map((column, index) =>
          column.subHeaderTexts.map((text, textIndex) => {
            return(
              <th
                key={`${index}_${textIndex}`}
              >
                {text}
              </th>
            )
          })
        )}
      </tr>
    )
  }

  render() {
    const { tableHeadColumns, borderLeftClassNames, borderRightClassNames, isHeader } = this.props

    return(
      <React.Fragment>
        { !isHeader && this.renderHeaderRow() }

        <tr className="top-header">
          {tableHeadColumns.map((column, index) =>
            index === 0 ?
              <th
                className={borderLeftClassNames}
                key={index}
                colSpan={column.subHeaderTexts.length}
              >
                {column.topHeader}
              </th>

            : index === (tableHeadColumns.length - 1) ?
              <th
                className={borderRightClassNames}
                key={index}
                colSpan={column.subHeaderTexts.length}
              >
                {column.topHeader}
              </th>

            : <th
                key={index}
                colSpan={column.subHeaderTexts.length}
              >
                {column.topHeader}
              </th>
          )}
        </tr>

        { isHeader && this.renderHeaderRow() }

      </React.Fragment>
    )
  }
}

export default Thead