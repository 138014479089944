import * as constants from './constants'
import axiosInstance from 'configurations/axios_instance'
import { MONTHLY_INDEX_PATH } from 'configurations/api_end_points'

export function getMonthlyData(stationId, month, year) {
  let params = {
    station_id: stationId
  }

  params['year'] = year
  params['month'] = month

  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.get(MONTHLY_INDEX_PATH, { params: params }).then(response => {
      dispatch({
        type: constants.GET_MONTHLY_DATA_SUCCESS,
        payload: response.data
      })
    }).catch().then(() => dispatch({type: 'UNBLOCK_UI'}))
  }
}