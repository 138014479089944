import * as constants from 'app/home/constants'
import axiosInstance from 'configurations/axios_instance'
import {
  STATION_INDEX_PATH,
  NOTICE_INDEX_PATH,
  SAVE_SURVEY_ANSWER_PATH,
  AIRPORTS_INDEX_PATH
} from 'configurations/api_end_points'

export function getListOfStations() {
  return (dispatch) => {
    axiosInstance.get(STATION_INDEX_PATH).then(response => {
      dispatch({
        type: constants.GET_STATION_DATA_SUCCESS,
        payload: response.data
      })
    })
  }
}

export function getListOfAirports() {
  return (dispatch) => {
    axiosInstance.get(AIRPORTS_INDEX_PATH).then(response => {
      dispatch({
        type: constants.GET_LIST_OF_AIRPORTS_SUCCESS,
        payload: response.data
      })
    })
  }
}

export function getNoticeData() {
  return (dispatch) => {
    axiosInstance.get(NOTICE_INDEX_PATH).then(response => {
      dispatch({
        type: constants.GET_NOTICE_DATA_SUCCESS,
        payload: response.data
      })
    }).catch(() => {
    })
  }
}

export function saveSurveyAnswer(is_useful) {
  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.post(SAVE_SURVEY_ANSWER_PATH, { is_useful: is_useful}).then(response => {
      dispatch({
        type: constants.SURVEY_IS_SAVED
      })
    }).catch().then(() => dispatch({type: 'UNBLOCK_UI'}))
  }
}