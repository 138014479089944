export function showMessage(message) {
  return {
    type: 'SHOW_NOTIF_MESSAGE',
    payload: { message: message }
  }
}

export function hideMessage() {
  return {
    type: 'HIDE_NOTIF_MESSAGE'
  }
}
