import * as constants from './constants'
import { extend } from 'lodash'

export function stationRealtimeReportReducer(state=[], action) {
  switch (action.type) {
    case constants.GET_STATION_REALTIME_REPORT_SUCCESS:
      return action.payload
    default:
      return state
  }
}