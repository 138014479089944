import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

class LeftSideHeader extends Component {
  render() {
    const { tableBodyRows } = this.props

    return(
      <Table hover size="sm" className="collapse-table-header">
        <tbody>
          <tr><td className="invisible">.</td></tr>
          <tr><td className="invisible">.</td></tr>

          {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
            <tr key={index} className={index % 2 !== 0 ? 'dark-row' : 'light-row'}>
              <td className={`${index===0 ? 'border-radius-top-left' : ''}`}>
                {row.header}
              </td>
            </tr>
          )}
        </tbody>

      </Table>
    )
  }
}

export default LeftSideHeader
