import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Accordion, Card, Table } from 'react-bootstrap'

import { getStationMonthlyReportData } from './actions'
import GuestTopNav from 'components/guest_top_nav'
import {getListOfStations} from '../home/actions'
import Collapse from './components/collapses'
import { push } from 'connected-react-router'
import Survey from '../home/components/survey'
import { find } from 'lodash'
import { saveSurveyAnswer } from '../home/actions'
import './styles.sass'
import ScrolltopButton from 'components/scrolltop_button'
import ImageModals from 'app/image-modals'

class StationMonthlyReport extends Component {
  constructor(props) {
    super(props)
    this.reloadDataFor = this.reloadDataFor.bind(this)
    this.changeSurveyAnswer = this.changeSurveyAnswer.bind(this)
    this.changeCollapeOfMonth = this.changeCollapeOfMonth.bind(this)

    this.state = {
      is_useful: true,
      activeKey: "0"
    }
  }

  changeSurveyAnswer(is_useful) {
    this.setState({is_useful: is_useful})
  }

  UNSAFE_componentWillMount() {
    const { router: { location: { query: { station_id, year } } } } = this.props

    if (!!station_id) {
      this.props.getStationMonthlyReportData(station_id, year)
    }

    this.props.getListOfStations()
  }

  reloadDataFor(stationId) {
    this.props.push(`/station_monthly_report?station_id=${stationId}`)
    this.props.getStationMonthlyReportData(stationId)
  }

  changeCollapeOfMonth(eventKey) {
    this.setState({ activeKey: eventKey })
  }

  render() {
    const { activeKey } = this.state
    const { dailyData, stationsData, stationMonthlyReport, savedSurvey } = this.props
    const { router: { location: { query: { station_id } } } } = this.props
    const station = find(stationsData.stations, {stationid: station_id})

    return(
      <React.Fragment>
        <GuestTopNav
          stationsData={stationsData}
          reloadDataFor={this.reloadDataFor}
          stationId={station_id}
        />

        <div className="content station-monthly-report-wrapper">
          <div className="green-text-wrapper">
            <span className="green-text">
              {
                !!station && station.name
              }
            </span>
          </div>

          <div className="top-time-wrapper">
            <span className="top-time-text">
              {new Date().getFullYear()}年度　月間騒音上位データ
            </span>
          </div>

          <Accordion defaultActiveKey={activeKey} activeKey={activeKey}>
            <div className="collapse-wrapper">
              {
                !!station_id ? (
                  stationMonthlyReport.map((station, index) => {
                    return(<Collapse
                      collapseHeaderText={station.collapseHeaderText}
                      key={index}
                      eventKey={`${index}`}
                      activeKey={activeKey}
                      tableBodyRows={station.tableBodyRows}
                      tableHeadColumns={station.tableHeadColumns}
                      changeCollapeOfMonth={this.changeCollapeOfMonth}
                    />)
                  })
                ) : (
                  <div>N/A</div>
                )
              }
            </div>
          </Accordion>
        </div>

        <ScrolltopButton />

        <Survey is_useful={this.state.is_useful}
                savedSurvey={savedSurvey}
                submitSurveyAnswer={() => this.props.saveSurveyAnswer(this.state.is_useful)}
                changeSurveyAnswer={this.changeSurveyAnswer}
        />

        <ImageModals />

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    router: state.router,
    stationMonthlyReport: state.stationMonthlyReport,
    stationsData: state.stationsData,
    savedSurvey: state.savedSurvey
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getStationMonthlyReportData,
    getListOfStations,
    saveSurveyAnswer,
    push
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(StationMonthlyReport)
