import * as constants from './constants'
import axiosInstance from 'configurations/axios_instance'
import { STATION_MONTHLY_REPORTS_INDEX_PATH } from 'configurations/api_end_points'

export function getStationMonthlyReportData(stationId, year) {
  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.get(STATION_MONTHLY_REPORTS_INDEX_PATH, {
      params: { station_id: stationId, year: year }
    }).then(response => {
      dispatch({
        type: constants.GET_STATION_MONTHLY_REPORT_SUCCESS,
        payload: response.data
      })
    }).catch().then(() => dispatch({type: 'UNBLOCK_UI'}))
  }

}