import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

import Thead from './thead'
import ContentRow from './content_row'
import LeftSideHeader from './left_side_header'
import RightSideButtons from './right_side_buttons'

class MonlthlyTable extends Component {

  render() {
    const { tableHeadColumns, goToDailyPage, tableBodyRows } = this.props

    return(
      <React.Fragment>
        <div className="table-left-header">
          <LeftSideHeader tableBodyRows={tableBodyRows} />
        </div>

        <div className="table-content monthly-report">
          <Table hover size="sm" className="collapse-table-content">

            {tableHeadColumns.map((column, index) =>
              column.subHeaderTexts.map((text, textIndex) => {
                return(<col key={textIndex} style={{width: "84px"}}/>)
              })
            )}

            <thead>
              <Thead
                tableHeadColumns={tableHeadColumns}
                borderLeftClassNames="border-radius-top-left"
                borderRightClassNames="border-radius-top-right"
                isHeader={true}
              />
            </thead>

            <tbody>
              {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
                <ContentRow
                  rowData={row}
                  rowIndex={index}
                  numberOfRows={tableBodyRows.length}
                  key={index}
                  tableHeadColumns={tableHeadColumns}
                  goToDailyPage={goToDailyPage}
                  day={row.day}
                  month={row.month}
                  year={row.year}
                />
              )}
            </tbody>

            <tfoot>
              <Thead
                tableHeadColumns={tableHeadColumns}
                borderLeftClassNames="border-radius-bottom-left footer-row"
                borderRightClassNames="border-radius-bottom-right footer-row"
                isHeader={false}
              />
            </tfoot>

          </Table>
        </div>

        <div className="table-right-content">
          <RightSideButtons
            goToDailyPage={goToDailyPage}
            tableBodyRows={tableBodyRows}
          />
        </div>

      </React.Fragment>
    )
  }
}

export default MonlthlyTable
