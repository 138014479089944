import React, { Component } from 'react'
import classNames from 'classnames'

class ContentRow extends Component {
  constructor(props) {
    super(props)
    this.renderColumn = this.renderColumn.bind(this)
  }

  renderColumn(columnName, index) {
    const { rowData } = this.props
    const tdClassNames = classNames({
      [`font-weight-normal ${ typeof(rowData.data[columnName]) == 'number' ? columnName : ''}`]: true
    })
    return <td className={tdClassNames} key={index}>{rowData.data[columnName]}</td>
  }

  render() {
    const { rowData, rowIndex, day, month, year, goToDailyPage } = this.props

    return(
      <tr className={`${rowIndex % 2 !== 0 ? 'dark-row' : 'light-row'} ${!!rowData.is_total_row ? 'total-row' : ''}`} onClick={() => goToDailyPage(day, month, year)}>
        {
          Object.keys(rowData['data']).map((columnName, index) => this.renderColumn(columnName, index))
        }
      </tr>
    )
  }
}

export default ContentRow
