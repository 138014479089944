import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

class LeftSideHeader extends Component {
  render() {
    const { tableBodyRows } = this.props

    return(
      <Table hover size="sm" className="collapse-table-header">
        <tbody>

          <tr><th className="invisible">.</th></tr>

          {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
            <React.Fragment key={index}>
              <tr key={index} className={index % 2 !== 0 ? 'dark-row' : 'light-row'}>
                <td className="left-side-td">
                  {row.header.split('\n').map((text, cindex) => {
                    return <div key={`${index}_${cindex}`}>{text}</div>
                  })}
                </td>
              </tr>

              {
                row.isShowingSound && (
                  <tr key={`${index}_1`} className={`${index % 2 !== 0 ? 'dark-row' : 'light-row'} extra-row`}>
                    <td className="col-border-top1-transparent">
                      <div style={{height: `${row.elementHeightOnSoundVisible}px`}} />
                    </td>
                  </tr>
                )
              }
            </React.Fragment>
          )}
        </tbody>

      </Table>
    )
  }
}

export default LeftSideHeader
