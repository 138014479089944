import axios from 'axios'
import cookie from 'react-cookies'

const token = cookie.load('X-API-TOKEN')

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    console.log('871673826183763612368638216816')
  }
  return error;
});

export function setAuthorizationToken(tk) {
  axiosInstance.defaults.headers.common.Authorization = `Token ${tk}`;
}

setAuthorizationToken(token)

export default axiosInstance
