import * as constants from 'app/monthly/constants'
import { extend } from 'lodash'

const monthlyInitialState = {
  collection: [],
  tableHeadColumns: []
}

export function monthlyDataReducer(state=monthlyInitialState, action) {
  switch (action.type) {
    case constants.GET_MONTHLY_DATA_SUCCESS:
      return action.payload
    default:
      return state
  }
}