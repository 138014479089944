import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Popover from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'

class Marker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowPopover: false
    }
    this.hidePopover = this.hidePopover.bind(this)
    this.showPopover = this.showPopover.bind(this)
    this.togglePopover = this.togglePopover.bind(this)
    this.renderPopoverForNonLoginPage = this.renderPopoverForNonLoginPage.bind(this)
    this.renderPopoverForLoggedinPage = this.renderPopoverForLoggedinPage.bind(this)
    this.isAirportMarker = this.isAirportMarker.bind(this)
    this.isShowPopover = this.isShowPopover.bind(this)
    this.popoverStatusChanged = false
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.mapZoom >=15 && nextProps.mapZoom < 15) {
      this.popoverStatusChanged = false
    }
  }

  hidePopover() {
    this.popoverStatusChanged = true
    this.setState({isShowPopover: false})
  }

  showPopover() {
    this.popoverStatusChanged = true
    this.setState({isShowPopover: true})
  }

  isShowPopover() {
    // if marker is outside the map, then do not display popover
    if (!this.props.isInBound) {
      return false
    }

    // return true
    if (this.popoverStatusChanged) {
      return this.state.isShowPopover
    } else {
      return (this.props.mapZoom >= 15)
    }
  }

  togglePopover() {
    this.props.toggleAllMarkers(true)

    if (this.isShowPopover()) {
      this.hidePopover()
    } else {
      this.showPopover()
    }
  }

  isAirportMarker() {
    const { marker} = this.props
    if (!!marker.stationid) {
      return false
    } else {
      return true
    }
  }

  renderPopoverForNonLoginPage(){
    const { marker, allMarkersEnabled, mapZoom } = this.props

    return(
      <Overlay
        placement="top"
        show={allMarkersEnabled && this.isShowPopover()}
        target={this.popoverTarget}
      >
        <Popover
          className="marker-popover"
          id={`popover-positioned-${marker.longitude}-${marker.latitude}`}
        >
          <Popover.Title as="h3">
            <span> {marker.name} </span>
            <span className="btn-close" onClick={this.hidePopover}>×</span>
          </Popover.Title>

          <Popover.Content>
            <div className="popover-link-wrapper text-center p-1">
              <Link
                className="popover-link"
                to={{
                  pathname: this.isAirportMarker() ? "/airport_monthly_report" : "/station_monthly_report",
                  search: this.isAirportMarker() ? `?airport_id=${marker.icao}` : `?station_id=${marker.stationid}`,
                }}
              >
                <img src={require('assets/images/arrow-right-2x.png')} className="mr-2 mt--4"/>

                測定結果
              </Link>

            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    )
  }

  renderPopoverForLoggedinPage(){
    const { marker, allMarkersEnabled, mapZoom, currentUser } = this.props
    return(
      <Overlay
        placement="top"
        show={allMarkersEnabled && this.isShowPopover()}
        target={this.popoverTarget}
      >
        <Popover
          className="marker-popover"
          id={`popover-positioned-${marker.longitude}-${marker.latitude}`}
        >
          <Popover.Title as="h3">
            <span> {marker.name} </span>
            <span className="btn-close" onClick={this.hidePopover}>×</span>
          </Popover.Title>

          <Popover.Content>
            <div>
              {
                !!marker.lasmax && (
                  <div className="maximum-wrapper">
                    <span className="maximum-label">最大</span>
                    <span className="maximum-value">{marker.lasmax}dB</span>
                    <span className="maximum-at">({marker.lasmaxtime})</span>
                  </div>
                )
              }
              {
                !!marker.number_of_data && (
                  <div className="times-wrapper">
                    <span className="times-label">回数</span>
                    <span className="times-value">{marker.number_of_data}回</span>
                  </div>
                )
              }

              <div className="popover-link-wrapper text-center pt-1">
                {
                  !this.isAirportMarker() && (
                    <Link
                      className="popover-link"
                      to={{
                        pathname: "/realtime_report",
                        search: `?station_id=${marker.stationid}`,
                      }}
                    >
                      <img src={require('assets/images/arrow-right-2x.png')} className="mr-2 mt--4"/>

                      最新騒音値
                    </Link>
                  )
                }

                <Link
                  className="popover-link"
                  to={{
                    pathname: "/monthly",
                    search: `?station_id=${marker.stationid}`,
                  }}
                >

                  <img src={require('assets/images/arrow-right-2x.png')} className="mr-2 mt--4"/>
                  月報
                </Link>

              </div>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    )
  }

  render() {
    const { marker, currentUser, loggedIn, mapZoom } = this.props

    return (
      <div className="marker-wrapper"
        ref={(marker) => {this.popoverTarget = marker}}
        onClick={this.togglePopover}>

        <img
          className="marker-no-hover"
          src={require('assets/images/marker_no_hover.png')}
        />

        <img
          className="marker-hover"
          src={require('assets/images/marker_hover.png')}
        />

        {
          loggedIn ?
            this.renderPopoverForLoggedinPage()
          :
            this.renderPopoverForNonLoginPage()
        }

        {
          mapZoom >= 13 && (
            <div className="marker-text">
              {marker.name}
            </div>
          )
        }
      </div>
    );
  }
}

export default Marker;