import * as constants from './constants'
import axiosInstance from 'configurations/axios_instance'
import { AIRPORT_MONTHLY_REPORTS_INDEX_PATH, EXPORT_STATIONS_PATH } from 'configurations/api_end_points'

export function getAirportMonthlyReportData(airportId) {
  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.get(AIRPORT_MONTHLY_REPORTS_INDEX_PATH, {
      params: { airport_id: airportId }
    }).then(response => {
      dispatch({
        type: constants.GET_AIRPORT_MONTHLY_REPORT_SUCCESS,
        payload: response.data
      })
    }).catch().then(() => dispatch({type: 'UNBLOCK_UI'}))
  }

}

export function exportStations(data) {
  const { reportType, stationIds, periodDateFrom, periodDateTo, periodMonthFrom, periodMonthTo, periodYearFrom, periodYearTo } = data;

  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.get(EXPORT_STATIONS_PATH, {
      params: {
        report_type: reportType,
        station_ids: stationIds,
        period_date_from: periodDateFrom,
        period_date_to: periodDateTo,
        period_month_from: periodMonthFrom,
        period_month_to: periodMonthTo,
        period_year_from: periodYearFrom,
        period_year_to: periodYearTo,
      }
    }).then(response => {
      const domain = response.config.baseURL.replace('/api/v1/', '')
      if(response.data.has_file) {
        window.open(
          `${domain}/${response.data.zip_file}`,
          '_blank'
        );
      } else {
        alert("ダウンロードできるファイルがありません")
      }
    }).catch(() => {
      dispatch({type: 'UNBLOCK_UI'})
    }).then(() => {
      dispatch({type: 'UNBLOCK_UI'})
    })
  }

}
