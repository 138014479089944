import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import cookie from 'react-cookies'

import { loginUser } from 'app/sign-in/actions'
import { push } from 'connected-react-router'
import Message from 'app/message'
import { hideMessage } from 'app/message/actions'

class SignIn extends Component {
  constructor(props) {
    super(props)
    this.loginUser = this.loginUser.bind(this)
    this.renderField = this.renderField.bind(this)
  }

  loginUser(formValues){
    this.props.loginUser(formValues.email, formValues.password)
  }

  renderField(field) {
    return(
      <div>
        <Form.Control {...field.input} type={field.type} placeholder={field.placeholder}/>
        {field.meta.touched && field.meta.error && <span>{field.meta.error}</span>}
      </div>
    )
  }

  UNSAFE_componentWillMount() {
    this.props.hideMessage()

    if (!!cookie.load('X-API-TOKEN')){
      this.props.push({pathname: '/' })
    }
  }

  render() {
    const { handleSubmit, reset, submitting, message, isShow } = this.props

    return(
      <div>
        <div className="title text-center mb-2">
          <h3>サインイン</h3>
        </div>

        <Form onSubmit={handleSubmit(this.loginUser)}>

          <Message message={ message } isShow={isShow}/>

          <Form.Group controlId="formBasicEmail" >
            <Form.Label>Eメール</Form.Label>
            <Field name="email" type="email" placeholder="Eメール" component={this.renderField}/>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>パスワード</Form.Label>
            <Field name="password" type="password" placeholder="パスワード" component={this.renderField}/>
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Link
              className="align-self-center"
              to={{pathname: "/forgot-password"}}
            >
              パスワードをお忘れですか？
            </Link>

            <Button variant="primary"
            type="submit"
            className="root-sea-btn btn-green align-self-center">
              参加する
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.message.message,
    isShow: state.message.isShow
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loginUser, push, hideMessage }, dispatch)
}

SignIn = reduxForm({form: 'signInForm'})(SignIn)

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
