import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Accordion, Card, Table } from 'react-bootstrap'
import { times, cloneDeep, find } from 'lodash'
import { push } from 'connected-react-router'
import qs from 'query-string'
import moment from "moment"

import { getMonthlyData } from './actions'
import Collapse from 'app/monthly/components/collapses'
import { getListOfStations } from '../home/actions'
import SignedInNav from 'components/signed_in_nav'
import Pagination from 'components/pagination'
import './styles.sass'

class Monthly extends Component {
  constructor(props) {
    super(props)
    this.reloadDataFor = this.reloadDataFor.bind(this)
    this.goToDailyPage = this.goToDailyPage.bind(this)
    this.changeCollapeOfMonth = this.changeCollapeOfMonth.bind(this)
    this.buildCsvData = this.buildCsvData.bind(this)
    this.onGoToPrevPage = this.onGoToPrevPage.bind(this)
    this.onGoToNextPage = this.onGoToNextPage.bind(this)
    this.prevPageEnabled = this.prevPageEnabled.bind(this)
    this.nextPageEnabled = this.nextPageEnabled.bind(this)
    this.prevPageText = this.prevPageText.bind(this)
    this.nextPageText = this.nextPageText.bind(this)
    this.prevYear = this.prevYear.bind(this)
    this.nextYear = this.nextYear.bind(this)
    this.state = {
      activeKey: ''
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getListOfStations()
    let { router: { location: { query: { station_id, year, month }} } } = this.props

    if (!station_id) { return this.props.push({pathname: '/'}) }

    if ((!month || !year) || (!!year && this.nextYear() > moment().year()+1)) {
      month = (new Date()).getMonth()+1
      year = moment().year()
      let query = { station_id: station_id, year: year, month: month }
      this.props.push({pathname: '/monthly', search: qs.stringify(query) })
    }

    this.setState({ activeKey: `${year}-${parseInt(month)}` })

    this.props.getMonthlyData(station_id, month, year)
  }

  reloadDataFor(stationId) {
    const { router: { location: { query: { year, month }} } } = this.props
    const query = { station_id: stationId, year: year, month: month }
    this.props.push({pathname: '/monthly', search: qs.stringify(query) })
    this.props.getMonthlyData(stationId, month, year)
  }

  goToDailyPage(day, month, year) {
    const { router: { location: { query: { station_id } } } } = this.props
    this.props.push(`/daily?station_id=${station_id}&day=${day}&month=${month}&year=${year}`)
  }

  changeCollapeOfMonth(month, year, eventKey) {
    const { router: { location: { query: { station_id } } } } = this.props
    this.setState({
      activeKey: eventKey
    })
    this.props.getMonthlyData(station_id, month, year)
    const query = { station_id: station_id, year: year, month: month }
    this.props.push({pathname: '/monthly', search: qs.stringify(query) })
  }

  buildCsvData() {
    let { router: { location: { query: { station_id, year, month }} } } = this.props

    let csvData = []
    const monthlyData = cloneDeep(this.props.monthlyData)

    let data = find(monthlyData.collection, (collection) => {
      return parseInt(collection.year) === parseInt(year) && parseInt(collection.month) === parseInt(month)
    })

    if (!!data) {
      csvData.push([data.collapseHeaderText]) // Append the year to each section
    }

    // Header of CSV
    let tableHeadColumns = ['']
    monthlyData.tableHeadColumns.forEach((tableHeadColumn) => {
      tableHeadColumn.subHeaderTexts.forEach((subHeaderText) => {
        tableHeadColumns.push(subHeaderText)
      })
    })
    csvData.push(tableHeadColumns)
    // finish render header for a section

    // Adding yearly data to csv
    if (!!data) {
      data.tableBodyRows.forEach((tableBodyRow) => {
        let row = Object.values(tableBodyRow.data)
        row.unshift(tableBodyRow.header)
        csvData.push(row)
      })
    }
    // Finished adding yearly data to csv

    return csvData
  }

  ///////////////////// PAGINATION STUFF /////////////////
  prevPageEnabled(){
    let { router: { location: { query: { year, month }} } } = this.props

    // the month 1, 2, 3 of 2011 is a part of 2010 Japanese financial year
    if (parseInt(year) <= 2010 || (parseInt(year) <= 2011 && month <= 3)) {
      return false
    }

    return true
  }

  nextPageEnabled(){
    let { router: { location: { query: { year, month }} } } = this.props

    if (!year || (month >= 4 && parseInt(year) >= moment().year())) {
      return false
    }

    return true
  }

  prevPageText() {
    return `${this.prevYear()-1}年度の月報を見る`
  }

  nextPageText() {
    let { router: { location: { query: { year, month }} } } = this.props

    if (month < 4 && year == moment().year()) {
      return `${this.nextYear()}年度の月報を見る`
    } else {
      return `${this.nextYear()-1}年度の月報を見る`
    }
  }

  onGoToPrevPage() {
    // the end of year always 03/YYYY (Japanese financial year)
    let month = 3

    let { router: { location: { query: { station_id }} } } = this.props

    let query = { station_id: station_id, year: this.prevYear(), month: month }
    this.props.getMonthlyData(station_id, month, this.prevYear())
    window.location.href = "/monthly?" + qs.stringify(query)
  }

  onGoToNextPage() {
    let { router: { location: { query: { station_id, month, year }} } } = this.props

    // if this is 03/2020 (parse of 2019 financial year), then next page will be current_month/2020
    if (parseInt(year) === moment().year() && parseInt(month) === 3) {
      month = moment().month()+1
    }

    if (this.nextYear() === moment().year() && parseInt(month) > moment().month()+1) {
      month = moment().month()+1
    }

    let query = { station_id: station_id, year: this.nextYear(), month: month }
    this.props.getMonthlyData(station_id, month, this.nextYear())
    window.location.href = "/monthly?" + qs.stringify(query)
  }

  prevYear() {
    let { router: { location: { query: { year, month }} } } = this.props
    let prevYear = null
    if (month >= 4 && year == moment().year()) {
      prevYear = parseInt(year)
    } else {
      prevYear = parseInt(year) - 1
    }
    return prevYear
  }

  nextYear() {
    let { router: { location: { query: { year, month }} } } = this.props
    let nextYear = null

    if (month < 4 && parseInt(year) === moment().year()) {
      nextYear = parseInt(year)
    } else {
      nextYear = parseInt(year) + 1
    }

    return nextYear
  }
  ///////////////////// END PAGINATION STUFF /////////////////

  render() {
    const { activeKey } = this.state
    const { stationsData, monthlyData } = this.props
    const { router: { location: { query: { station_id, year, month } } } } = this.props
    const station = find(stationsData.stations, {stationid: station_id})

    let dayObject = new Date(year, month-1, 1)

    return(
      <React.Fragment>
        <SignedInNav
          stationsData={stationsData}
          reloadDataFor={this.reloadDataFor}
          activeLink="monthly"
          stationId={station_id}
          csvData={this.buildCsvData()}
          year={year}
          month={month}
          fileName={`月報-${moment(dayObject).format('YYYYMM')}-${station_id}.csv`}
        />

        <div className="content monthly-report">
          <div className="green-text-wrapper">
            <span className="green-text">
              {
                !!station && station.name
              }
            </span>
          </div>

          <div className="top-time-wrapper">
            <span className="top-time-text">
              {monthlyData.year}年度　月報
            </span>
          </div>

          <Accordion defaultActiveKey={activeKey} activeKey={activeKey}>
            <div className="collapse-wrapper">
              {
                monthlyData.collection.map((data, index) => {
                  return(<Collapse
                    collapseHeaderText={data.collapseHeaderText}
                    eventKey={`${data.year}-${parseInt(data.month)}`}
                    key={index}
                    tableBodyRows={data.tableBodyRows}
                    tableHeadColumns={data.tableHeadColumns}
                    goToDailyPage={this.goToDailyPage}
                    activeKey={activeKey}
                    month={data.month}
                    year={data.year}
                    changeCollapeOfMonth={this.changeCollapeOfMonth}
                  />)
                })
              }
            </div>
          </Accordion>
        </div>

        <Pagination
          page="monthly"
          prevPageEnabled={this.prevPageEnabled()}
          nextPageEnabled={this.nextPageEnabled()}
          prevPageText={this.prevPageText()}
          nextPageText={this.nextPageText()}
          onGoToPrevPage={this.onGoToPrevPage}
          onGoToNextPage={this.onGoToNextPage}
        />

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    router: state.router,
    monthlyData: state.monthlyData,
    stationsData: state.stationsData
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    push,
    getMonthlyData,
    getListOfStations
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Monthly)
