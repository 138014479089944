import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cookie from 'react-cookies'
import { push } from 'connected-react-router'
import { isIE } from 'react-device-detect'
import Modal from 'react-bootstrap/Modal'

import axiosInstance from 'configurations/axios_instance'
import { logoutUser } from 'app/sign-in/actions'
import { LOGIN_SUCCESS } from 'app/sign-in/constants'
import { setAuthorizationToken } from 'configurations/axios_instance'

import SignedIn from 'components/layouts/signed_in'
import Guest from 'components/layouts/guest'
import Auth from 'components/layouts/auth'
import Daily from '../app/daily'
import Monthly from '../app/monthly'
import Yearly from '../app/yearly'
import BatchReport from '../app/batch-report'
import RealtimeReport from '../app/realtime_report'
import HomeNonLogin from '../app/home/home-non-login'
import HomeLoggedIn from '../app/home/home-logged-in'
import SignIn from '../app/sign-in'
import ForgotPassword from '../app/forgot-password'
import ResetPassword from '../app/reset-password'
import StationMonthlyReport from '../app/station_monthly_report'
import AirportMonthlyReport from '../app/airport_monthly_report'

const isAccessableWithoutLogin = (pathname) => {
  const accessablePathName = [
    '/', '/sign-in', '/forgot-password', '/reset-password'
  ]

  return accessablePathName.indexOf(pathname) > -1
}

const checkValidToken = (store) => {
  axiosInstance.get('profile').then((response) => {
    store.dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data
    })
  }, error => {
    store.dispatch(logoutUser())
  })
}

class PrivateRoute extends Component {

  render(){
    const { store } = this.props

    if (!cookie.load('X-API-TOKEN')) {
      return(
        <Route
          render={({location}) =>
            isAccessableWithoutLogin(location.pathname) ? (
              <Route {...this.props}>
                { this.props.children }
              </Route>
            ) : (
              <Redirect to="/sign-in"/>
            )
          }
        />
      )
    } else {
      checkValidToken(store)

      return(
        <Route {...this.props}>
          { this.props.children }
        </Route>
      )
    }

  }
}

class Root extends Component {

  render() {
    let hostname = window.location.hostname
    const { store } = this.props

    if (isIE) return(
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>Internet Explorerには対応していません</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>本サイトはお使いのブラウザーには対応していません。</p>
          <p>Google Chrome、Microsoft Edgeをご利用ください。</p>
        </Modal.Body>
      </Modal.Dialog>
    )

    return(
      <Switch>
        {
          process.env.REACT_APP_PRIVATE_URL.indexOf(hostname) > -1 && (
            <Route>
              <Route path="/" exact store={store}>
                <SignedIn>
                  <HomeLoggedIn />
                </SignedIn>
              </Route>

              <PrivateRoute path="/daily" store={store} >
                <SignedIn>
                  <Daily />
                </SignedIn>
              </PrivateRoute>

              <PrivateRoute path="/monthly" store={store} >
                <SignedIn>
                  <Monthly />
                </SignedIn>
              </PrivateRoute>

              <PrivateRoute path="/batch-report" store={store} >
                <SignedIn>
                  <BatchReport />
                </SignedIn>
              </PrivateRoute>

              <PrivateRoute path="/yearly" store={store} >
                <SignedIn>
                  <Yearly />
                </SignedIn>
              </PrivateRoute>

              <PrivateRoute path="/realtime_report" store={store} >
                <SignedIn>
                  <RealtimeReport />
                </SignedIn>
              </PrivateRoute>

              <Route path="/sign-in" store={store}>
                <Auth>
                  <SignIn />
                </Auth>
              </Route>

              <Route path="/forgot-password">
                <Auth>
                  <ForgotPassword />
                </Auth>
              </Route>

              <Route path="/reset-password">
                <Auth>
                  <ResetPassword />
                </Auth>
              </Route>
            </Route>
          )
        }


        {
          process.env.REACT_APP_PUBLIC_URL.indexOf(hostname) > -1 && (
            <Route>
              <Route path="/" exact store={store}>
                <Guest>
                  <HomeNonLogin />
                </Guest>
              </Route>

              <Route path="/station_monthly_report" store={store} >
                <Guest>
                  <StationMonthlyReport />
                </Guest>
              </Route>

              <Route path="/airport_monthly_report" store={store} >
                <Guest>
                  <AirportMonthlyReport />
                </Guest>
              </Route>
            </Route>
          )
        }

      </Switch>

    )
  }
}

export default Root