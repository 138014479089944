import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

import Thead from './thead'
import ContentRow from './content_row'
import LeftSideHeader from './left_side_header'

class AirportMonlthlyTable extends Component {
  render() {
    const { tableHeadColumns, tableBodyRows } = this.props

    return(
      <React.Fragment>
        <div className="tables-wrapper">
          <div className="table-left-header">
            <LeftSideHeader tableBodyRows={tableBodyRows} />
          </div>

          <div className="table-content airport-monthly-report">
            <Table hover size="sm" className="collapse-table-content">
              {tableHeadColumns.map( (e, index) => (index > 0) && <col key={index} style={{width: "120px"}}/>)}

              {tableHeadColumns.map((column, index) =>
                column.subHeaderTexts.map((text, textIndex) => {
                  return(<col key={`${index}-${textIndex}`} style={{width: "120px"}}/>)
                })
              )}

              <thead>
                <Thead
                  tableHeadColumns={tableHeadColumns}
                  borderLeftClassNames="border-radius-top-left"
                  borderRightClassNames="border-radius-top-right"
                />
              </thead>

              <tbody>
                {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
                  <ContentRow
                    rowData={row}
                    rowIndex={index}
                    numberOfRows={tableBodyRows.length}
                    key={index}
                    tableHeadColumns={tableHeadColumns}
                  />
                )}
              </tbody>

            </Table>
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default AirportMonlthlyTable
