import React, { Component } from "react"

class Footer extends Component {
  render() {
    return(
      <footer id="footer">
        <div className="footer-content">
          <p className="mb-0">
            沖縄県　環境保全課
            <br/>
            〒900-8570　沖縄県那覇市泉崎1-2-2
            <br/>
            Tel：098-866-2236　Fax：098-866-2240
            <br/>
            E-mail：aa038008@pref.okinawa.lg.jp
          </p>

          <p className="copyright">
            Copyright © Okinawa Prefectural Government. All Rights Reserved.
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer