import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import qs from 'query-string'

import CustomReactCsv from "components/customizes/custom-react-csv"
import { saveDownLoadCsvLocation } from 'components/download_csv_location/actions'

class SignedInNav extends Component {
  constructor(props) {
    super(props)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.state = { csvData: [] }
  }

  onFilterChange(e) {
    this.props.reloadDataFor(e.target.value)
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.csvData && this.props.csvData !== this.state.csvData) {
      this.setState({csvData: this.props.csvData})
    }
  }

  render() {
    const {
      stationsData, activeLink, stationId, fileName,
      year, month, day
    } = this.props

    const query = { station_id: stationId, year: year, month: month, day: day }

    let { csvData } = this.state

    return(
      <Navbar collapseOnSelect expand="lg" className="top-navigation">
        <Link className="brand-text navbar-brand" to={{pathname: "/"}}>
          <span className='brand-icon'>
            <img src={require('assets/images/pagination-left-icon.png')}/>
          </span>
          <span>
            トップへ戻る
          </span>
        </Link>

        <Nav className="mr-auto">
          <select className="header-select-1 form-control" onChange={this.onFilterChange} value={stationId}>
            {
              !!stationsData && !!stationsData.stations && stationsData.stations.map((station, index) => {
                return(
                  <option
                    value= {station.stationid}
                    key={index}
                  >
                    { station.name }
                  </option>
                )
              })
            }
          </select>
        </Nav>

        <Nav>
          <Nav>
            <Link
              className={`root-sea-btn ml-2 ${activeLink === 'realtime' ? 'btn-active' : ''}`}
              to={{
                pathname: "/realtime_report", query: { station_id: stationId },
                search: qs.stringify(query)
              }}
            >
              最新騒音値
            </Link>

            <Link
              className={`root-sea-btn ml-2 ${activeLink === 'daily' ? 'btn-active' : ''}`}
              to={{
                pathname: "/daily", query: { station_id: stationId },
                search: qs.stringify(query)
              }}
            >
              日報
            </Link>

            <Link
              className={`root-sea-btn ml-2 ${activeLink === 'monthly' ? 'btn-active' : ''}`}
              to={{
                pathname: "/monthly", query: { station_id: stationId },
                search: qs.stringify(query)
              }}
            >
              月報
            </Link>

            <Link
              className={`root-sea-btn ml-2 ${activeLink === 'yearly' ? 'btn-active' : ''}`}
              to={{
                pathname: "/yearly", query: { station_id: stationId },
                search: qs.stringify(query)
              }}
            >
              年報
            </Link>

            <CustomReactCsv data={csvData}
            className={`root-sea-btn ml-2 text-white btn-green`}
            filename={!!fileName ? fileName : null}
            onClick={() => this.props.saveDownLoadCsvLocation(window.location.href)}
            >
              データ
              <br/>
              ダウンロード
            </CustomReactCsv>

          </Nav>
        </Nav>
      </Navbar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    router: state.router
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    saveDownLoadCsvLocation
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInNav)
