import React, { Component } from 'react'

class Thead extends Component {

  render() {
    const { tableHeadColumns, borderLeftClassNames, borderRightClassNames } = this.props

    return(
      <React.Fragment>

        <tr className="top-header">
          {tableHeadColumns.map((column, index) =>
            index === 0 ?
              <th
                className={borderLeftClassNames}
                key={index}
                colSpan={ column.subHeaderTexts.length}
                rowSpan={ column.subHeaderTexts.length === 0 ? '2' : '1' }
              >
                {column.topHeader}
              </th>

            : index === (tableHeadColumns.length - 1) ?
              <th
                className={borderRightClassNames}
                key={index}
                colSpan={ column.subHeaderTexts.length }
                rowSpan={ column.subHeaderTexts.length === 0 ? '2' : '1' }
              >
                {column.topHeader}
              </th>

            : <th
                key={index}
                colSpan={ column.subHeaderTexts.length }
                rowSpan={ column.subHeaderTexts.length === 0 ? '2' : '1' }
              >
                {column.topHeader}
              </th>
          )}
        </tr>

        <tr>
          {tableHeadColumns.map((column, index) =>
            column.subHeaderTexts.map((text, textIndex) => {
              return(
                <th
                  key={`${index}_${textIndex}`}
                >
                  {text}
                </th>
              )
            })
          )}
        </tr>

      </React.Fragment>
    )
  }
}

export default Thead