import React, { Component } from 'react'
import BlockUi from 'react-block-ui'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import 'assets/styles/auth.sass'

class Auth extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { isBlockUI } = this.props

    return(
      <BlockUi tag="div" blocking={isBlockUI} >
        <div className="root-sea-signed-out">
          {this.props.children}
        </div>
      </BlockUi>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isBlockUI: state.isBlockUI,
  }
}

export default connect(mapStateToProps, null)(Auth)