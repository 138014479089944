import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-block-ui/style.css'
import 'assets/styles/index.sass'
import 'assets/styles/block-ui.sass'
import 'assets/styles/custom-radio-button.sass'

import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import ReduxThunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import Root from './components/root'
import rootReducer from './reducers'
import * as serviceWorker from './serviceWorker'

import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import { translationsObject } from './locales'

export const history = createBrowserHistory()

const store = createStore(
  rootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
      ReduxThunk
    )
  )
)

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale('ja'));

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Root store={store} history={history}/>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
