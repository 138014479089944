import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap'

class Survey extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { is_useful, savedSurvey } = this.props

    return (
      <div className="bottom-survey d-flex align-items-center">
        <div className="survey-button">アンケート</div>
        {
          savedSurvey ? (
            <span className='survey-question'>ご回答ありがとうございました。</span>
          ) : (
            <React.Fragment>
              <span className='survey-question'>このサイトは役に立ちましたか？</span>
              <div className="survey-radio">
                {
                  [{label: 'はい', value: true}, {label: 'いいえ', value: false}].map(
                    (item, index) => (
                      <label
                        key={index}
                        className="input-container survey-radio-button"
                      >
                        <span>{ item.label }</span>
                        <input
                          type="radio"
                          checked={is_useful==item.value}
                          name="radio"
                          value={item.value}
                          onChange={() => this.props.changeSurveyAnswer(item.value)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    )
                  )
                }
                <Button
                  className="survey-submit-button"
                  variant="success"
                  type="submit"
                  onClick={this.props.submitSurveyAnswer}
                >
                  送信する
                </Button>
              </div>
            </React.Fragment>
          )
        }
      </div>
    )
  }
}

export default Survey