import React, { Component } from "react";

class Counter extends Component {
  render() {
    const { selectedRows, totalRows } = this.props;

    return (
      <div className="text-right panel-small-text pb-3">
        {selectedRows || 0} / {totalRows || 0} 件選択中
      </div>
    );
  }
}

export default Counter;
