import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class ExportTopNav extends Component {
  render() {
    return(
      <Navbar collapseOnSelect expand="lg" className="top-navigation">
        <Link className="brand-text navbar-brand" to={{pathname: "/"}}>
          <span className='brand-icon'>
            <img src={require('assets/images/pagination-left-icon.png')}/>
          </span>
          <span>
            トップへ戻る
          </span>
        </Link>

      </Navbar>
    )
  }
}

export default (ExportTopNav)
