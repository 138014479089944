import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'

import { resetPassword } from 'app/reset-password/actions'
import { push } from 'connected-react-router'
import Message from 'app/message'
import { hideMessage } from 'app/message/actions'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.resetPassword = this.resetPassword.bind(this)
    this.renderField = this.renderField.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.props.hideMessage()
  }

  resetPassword(formValues){
    const { router: { location: { query: { reset_password_token }}}} = this.props
    this.props.resetPassword(
      formValues.email,
      formValues.password,
      formValues.password_confirmation,
      reset_password_token
    )
  }

  renderField(field) {
    return(
      <div>
        <Form.Control {...field.input} type={field.type} placeholder={field.placeholder}/>
        {field.meta.touched && field.meta.error && <span>{field.meta.error}</span>}
      </div>
    )
  }

  render() {
    const { handleSubmit, reset, submitting, message, isShow } = this.props

    return(
      <div>
        <div className="title text-center mb-2">
          <h3>パスワードを再設定する</h3>
        </div>

        <Form onSubmit={handleSubmit(this.resetPassword)}>

          <Message message={ message } isShow={isShow}/>

          <Form.Group controlId="formPassword" >
            <Form.Label>パスワード</Form.Label>
            <Field name="password" type="password" placeholder="パスワード" component={this.renderField}/>
          </Form.Group>

          <Form.Group controlId="formPasswordConfirmation" >
            <Form.Label>パスワード確認</Form.Label>
            <Field name="password_confirmation" type="password" placeholder="パスワード確認" component={this.renderField}/>
          </Form.Group>

          <div className="text-right">
            <Button variant="primary" type="submit" className="root-sea-btn btn-green">
              参加する
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.message.message,
    isShow: state.message.isShow,
    router: state.router
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ resetPassword, push, hideMessage }, dispatch)
}

ForgotPassword = reduxForm({form: 'resetPasswordForm'})(ForgotPassword)

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
