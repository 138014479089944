import * as constants from './constants'
import axiosInstance from 'configurations/axios_instance'
import { RESET_PASSWORD_PATH } from 'configurations/api_end_points'
import { Route, Redirect } from 'react-router-dom'
import { push } from 'connected-react-router'
import { showMessage } from 'app/message/actions'

export function resetPassword(email, password, password_confirmation, reset_password_token) {
  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.post(RESET_PASSWORD_PATH, {
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      reset_password_token: reset_password_token
    }).then((response) => {
      dispatch(push('/sign-in'))
    }, error => {
      dispatch(showMessage(error.response.data.message))
    }).then(() => dispatch({type: 'UNBLOCK_UI'}))
  }
}
