import * as constants from 'app/yearly/constants'
import axiosInstance from 'configurations/axios_instance'
import { YEARLY_INDEX_PATH } from 'configurations/api_end_points'

export function getYearlyData(stationId, year) {
  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.get(YEARLY_INDEX_PATH, {
      params: {
        station_id: stationId,
        year: year
      }
    }).then(response => {
      dispatch({
        type: constants.GET_YEARLY_DATA_SUCCESS,
        payload: response.data
      })
    }).catch().then(() => dispatch({type: 'UNBLOCK_UI'}))
  }
}