import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

class LeftSideHeader extends Component {
  render() {
    const { tableBodyRows } = this.props

    return(
      <Table hover size="sm" className="collapse-table-header">
        <tbody>

          <tr><th className="invisible">.</th></tr>

          {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
            <tr key={index} className={`${index % 2 !== 0 ? 'dark-row' : 'light-row'} ${!!row.is_total_row ? 'total-row' : ''}`}>
              <td className="left-side-td">
                {row.header.split('\n').map((text, i) => {
                  return <div key={`${index}-${i}`}>{text}</div>
                })}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )
  }
}

export default LeftSideHeader
