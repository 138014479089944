import React, { Component } from 'react'
import { Table, Button } from 'react-bootstrap'
import moment from 'moment'

class RightSideButtons extends Component {
  render() {
    const { tableBodyRows, toggleSound, goToMonthlyPage } = this.props

    return(
      <Table hover size="sm" className="collapse-table-right-side">
        <tbody>
          <tr><td className="invisible">.</td></tr>
          {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
            !row.is_total_row && (
              <tr key={index} className={`${index % 2 !== 0 ? 'dark-row' : 'light-row'}`}>
                <td className={index===0 ? 'border-radius-top-right' : ''}>
                  {
                    (moment().year() - 2) < parseInt(row.year) && (
                      <Button className="btn-show-playlist"
                              onClick={() => goToMonthlyPage(row.month, row.year)}>
                        詳細
                        <span className='arrow-right btn-arrow'> </span>
                      </Button>
                    )
                  }
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    )
  }
}

export default RightSideButtons

