import React, { Component } from 'react'
import classNames from 'classnames'

class ContentRow extends Component {
  constructor(props) {
    super(props)
    this.renderColumn = this.renderColumn.bind(this)
  }

  renderColumn(columnName, index) {
    const { rowData } = this.props
    const tdClassNames = classNames({
      [`font-weight-normal ${columnName}`]: true
    })
    return <td className={tdClassNames} key={index}>{rowData.data[columnName]}</td>
  }

  render() {
    // Each row will return "year" and "month" to let button "詳細" goes to correct monthly page
    const { rowData, rowIndex, goToMonthlyPage, month, year } = this.props

    return(
      <tr className={`${rowIndex % 2 !== 0 ? 'dark-row' : 'light-row'} ${!!rowData.is_total_row ? 'total-row' : ''}`}
        onClick={() => !rowData.is_total_row ? goToMonthlyPage(month, year) : () => false}
      >
        {
          Object.keys(rowData['data']).map((columnName, index) => this.renderColumn(columnName, index))
        }
      </tr>
    )
  }
}

export default ContentRow
