import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Accordion, Card, Table } from 'react-bootstrap'
import { times, find } from 'lodash'
import { push } from 'connected-react-router'

import ScrolltopButton from 'components/scrolltop_button'
import { getAirportMonthlyReportData } from './actions'
import Collapse from './components/collapses'
import GuestTopNav from 'components/guest_top_nav'
import {getListOfAirports} from '../home/actions'
import Survey from '../home/components/survey'
import { saveSurveyAnswer } from '../home/actions'
import './styles.sass'

class AirportMonthlyReport extends Component {
  constructor(props) {
    super(props)
    this.reloadDataFor = this.reloadDataFor.bind(this)
    this.changeSurveyAnswer = this.changeSurveyAnswer.bind(this)
    this.changeCollapeOfMonth = this.changeCollapeOfMonth.bind(this)

    this.state = {
      is_useful: true,
      activeKey: "0"
    }
  }

  UNSAFE_componentWillMount() {
    const { router: { location: { query: { airport_id } } } } = this.props
    if (!!airport_id) {
      this.props.getAirportMonthlyReportData(airport_id)
    }
    this.props.getListOfAirports()
  }

  reloadDataFor(airportId) {
    this.props.push(`/airport_monthly_report?airport_id=${airportId}`)
    this.props.getListOfAirports()
    this.props.getAirportMonthlyReportData(airportId)
  }

  changeSurveyAnswer(is_useful) {
    this.setState({is_useful: is_useful})
  }

  changeCollapeOfMonth(eventKey) {
    this.setState({ activeKey: eventKey })
  }

  render() {
    const { activeKey } = this.state
    const { airportsData, savedSurvey, airportMonthlyReportData } = this.props
    const { router: { location: { query: { airport_id } } } } = this.props
    const airport = find(airportsData.airports, {icao: airport_id})

    return(
      <React.Fragment>
        <GuestTopNav
          airportsData={airportsData}
          reloadDataFor={this.reloadDataFor}
          airportId={airport_id}
        />

        <div className="content airport-monthly-report-wrapper">
          <div className="green-text-wrapper">
            <span className="green-text">
              {
                !!airport && airport.name
              }
            </span>
          </div>

          <div className="top-time-wrapper">
            <span className="top-time-text">
              {new Date().getFullYear()}年度　月報
            </span>
          </div>

          <Accordion defaultActiveKey={activeKey} activeKey={activeKey}>
            <div className="collapse-wrapper">
              {
                airportMonthlyReportData.collection.map((data, index) => {
                  return(<Collapse
                    collapseHeaderText={data.collapseHeaderText}
                    eventKey={`${index}`}
                    key={index}
                    activeKey={activeKey}
                    tableBodyRows={data.tableBodyRows}
                    tableHeadColumns={data.tableHeadColumns}
                    changeCollapeOfMonth={this.changeCollapeOfMonth}
                  />)
                })
              }
            </div>
          </Accordion>
        </div>

        <ScrolltopButton />

        <Survey is_useful={this.state.is_useful}
                savedSurvey={savedSurvey}
                submitSurveyAnswer={() => this.props.saveSurveyAnswer(this.state.is_useful)}
                changeSurveyAnswer={this.changeSurveyAnswer}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    router: state.router,
    airportsData: state.airportsData,
    airportMonthlyReportData: state.airportMonthlyReportData,
    savedSurvey: state.savedSurvey
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getListOfAirports,
    saveSurveyAnswer,
    push,
    getAirportMonthlyReportData
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AirportMonthlyReport)
