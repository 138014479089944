import React, { Component } from 'react'

class Thead extends Component {
  render() {
    const { tableHeadColumns, borderLeftClassNames, borderRightClassNames } = this.props

    return(
      <tr>
        {tableHeadColumns.map((column, index) =>
          index === 0 ?
            <th className={borderLeftClassNames} key={index}>{column}</th>
          :
          index === (tableHeadColumns.length-1) ?
            <th className={borderRightClassNames} key={index}>{column}</th>
          :
            <th key={index}>{column}</th>
        )}
      </tr>
    )
  }
}

export default Thead