import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Accordion } from "react-bootstrap";
import { push } from "connected-react-router";
import moment from "moment";

import BatchReportTable from "./components/tables";
import ReportType from "./components/form/report-type.jsx";
import ReportPeriod from "./components/form/report-period.jsx";
import DownloadButton from "./components/form/report-download-button.jsx";
import { getListOfStations } from "../home/actions";
import { exportStations } from "./actions";
import ExportTopNav from 'components/export_top_nav'

import "./styles.sass";

class BatchReport extends Component {
  constructor(props) {
    super(props);
    this.setReportType = this.setReportType.bind(this);
    this.setPeriodDateFrom = this.setPeriodDateFrom.bind(this);
    this.setPeriodDateTo = this.setPeriodDateTo.bind(this);
    this.setPeriodMonthFrom = this.setPeriodMonthFrom.bind(this);
    this.setPeriodMonthTo = this.setPeriodMonthTo.bind(this);
    this.setPeriodYearFrom = this.setPeriodYearFrom.bind(this);
    this.setPeriodYearTo = this.setPeriodYearTo.bind(this);
    this.setStationIds = this.setStationIds.bind(this);
    this.handleDownloadReport = this.handleDownloadReport.bind(this);
    this.state = {
      reportType: "daily",
      periodDateFrom: moment().subtract(1, "day").format("YYYY-MM-DD"),
      periodDateTo: moment().subtract(1, "day").format("YYYY-MM-DD"),
      periodMonthFrom: moment().subtract(1, "months").format("YYYY-MM"),
      periodMonthTo: moment().subtract(1, "months").format("YYYY-MM"),
      periodYearFrom: moment().subtract(3, "months").year() - 1,
      periodYearTo: moment().subtract(3, "months").year() - 1,
      stationIds: []
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getListOfStations();
  }

  setReportType(v) {
    this.setState({ reportType: v });
  }

  setPeriodDateFrom(v) {
    this.setState({ periodDateFrom: v });
  }

  setPeriodDateTo(v) {
    this.setState({ periodDateTo: v });
  }

  setPeriodMonthFrom(v) {
    this.setState({ periodMonthFrom: v });
  }

  setPeriodMonthTo(v) {
    this.setState({ periodMonthTo: v });
  }

  setPeriodYearFrom(v) {
    this.setState({ periodYearFrom: v });
  }

  setPeriodYearTo(v) {
    this.setState({ periodYearTo: v });
  }

  setStationIds(v) {
    this.setState({ stationIds: v });
  }

  handleDownloadReport() {
    const { reportType, stationIds, periodDateFrom, periodDateTo, periodMonthFrom, periodMonthTo, periodYearFrom, periodYearTo } = this.state;

    if (
      ((!periodDateFrom || !periodDateTo) && reportType === "daily") ||
      ((!periodMonthFrom || !periodMonthTo) && reportType === "monthly") ||
      ((!periodYearFrom || !periodYearTo) && reportType === "annual")
    ) {
      alert("正しい期間を指定してください")
      return;
    }

    const dateFrom = new Date(periodDateFrom);
    const dateTo = new Date(periodDateTo);
    const monthFrom = new Date(periodMonthFrom);
    const monthTo = new Date(periodMonthTo);

    if (
        (dateTo < dateFrom && reportType === "daily") ||
        (monthTo < monthFrom && reportType === "monthly") ||
        (periodYearTo < periodYearFrom && reportType === "annual")
      ) {
      alert("正しい期間を指定して下さい")
      return;
    }

    const isMonthInFuture = (moment(monthFrom).startOf('month') > moment().startOf('month')) || (moment(monthFrom).startOf('month') > moment().startOf('month'))
    const isYearInFuture = (periodYearFrom > moment().year()) || (periodYearTo > moment().year())

    if (
      ((dateTo > new Date() || dateFrom > new Date()) && reportType === "daily") ||
      (isMonthInFuture && reportType === "monthly") ||
      (isYearInFuture && reportType === "annual")
    ) {
      alert("未来の期間は指定できません")
      return;
    }

    if (stationIds.length === 0) {
      alert("測定局を選択してください。")
      return;
    }
    this.props.exportStations({reportType, stationIds, periodDateFrom, periodDateTo, periodMonthFrom, periodMonthTo, periodYearFrom, periodYearTo});
  }

  render() {
    const { stationsData } = this.props;
    return (
      <React.Fragment>
        <ExportTopNav />
        <div className="content batch-report">
          <div className="top-time-wrapper">
            <span className="top-time-text">帳票</span>
          </div>

          <div className="batch-report-form">
            <ReportType
              reportType={this.state.reportType}
              setReportType={this.setReportType}
            />
            <ReportPeriod
              reportType={this.state.reportType}
              // date from
              periodDateFrom={this.state.periodDateFrom}
              setPeriodDateFrom={this.setPeriodDateFrom}
              // date to
              periodDateTo={this.state.periodDateTo}
              setPeriodDateTo={this.setPeriodDateTo}
              // month
              periodMonthFrom={this.state.periodMonthFrom}
              setPeriodMonthFrom={this.setPeriodMonthFrom}
              periodMonthTo={this.state.periodMonthTo}
              setPeriodMonthTo={this.setPeriodMonthTo}
              // year
              periodYearFrom={this.state.periodYearFrom}
              setPeriodYearFrom={this.setPeriodYearFrom}
              periodYearTo={this.state.periodYearTo}
              setPeriodYearTo={this.setPeriodYearTo}
            />
            <DownloadButton
              dataSubmit={this.state}
              handleDownloadReport={this.handleDownloadReport}
            />
          </div>

          <Accordion defaultActiveKey="batch-report" activeKey="batch-report">
            <BatchReportTable tableBodyRows={stationsData.stations} setStationIds={this.setStationIds} stationIds={this.state.stationIds}/>
          </Accordion>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    router: state.router,
    stationsData: state.stationsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      push,
      getListOfStations,
      exportStations,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchReport);
