import * as constants from './constants'
import axiosInstance from 'configurations/axios_instance'
import { AIRPORT_MONTHLY_REPORTS_INDEX_PATH } from 'configurations/api_end_points'

export function getAirportMonthlyReportData(airportId) {
  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.get(AIRPORT_MONTHLY_REPORTS_INDEX_PATH, {
      params: { airport_id: airportId }
    }).then(response => {
      dispatch({
        type: constants.GET_AIRPORT_MONTHLY_REPORT_SUCCESS,
        payload: response.data
      })
    }).catch().then(() => dispatch({type: 'UNBLOCK_UI'}))
  }

}