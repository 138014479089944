import * as constants from './constants'
import { extend } from 'lodash'

export function savedDownloadCsvLocationReducer(state=false, action) {
  switch (action.type) {
    case constants.DOWNLOAD_CSV_IS_SAVED:
      return true
    default:
      return state
  }
}