import React, { Component } from 'react'
import { Button, Alert } from 'react-bootstrap'

class Message extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {message, isShow} = this.props
    return(
      <Alert show={isShow} variant="info">
        { message }
      </Alert>
    )
  }
}

export default Message
