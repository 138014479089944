import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import HeaderTop from './header_top'
import BlockUi from 'react-block-ui'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Footer from './footer'

class SignedIn extends Component {
  render() {
    const { isBlockUI } = this.props

    return(
      <BlockUi tag="div" blocking={isBlockUI} >
        <div className="root-sea-signed-in">

          <HeaderTop />

          {this.props.children}

          <Footer />
        </div>
      </BlockUi>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isBlockUI: state.isBlockUI,
  }
}

export default connect(mapStateToProps, null)(SignedIn)
