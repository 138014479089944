import * as constants from './constants'
import axiosInstance from 'configurations/axios_instance'
import { FORGOT_PASSWORD_PATH } from 'configurations/api_end_points'
import { Route, Redirect } from 'react-router-dom'
import { push } from 'connected-react-router'
import { showMessage, hideMessage } from 'app/message/actions'

export function sendResetPasswordInstructions(email) {
  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})
    axiosInstance.post(FORGOT_PASSWORD_PATH, {
      email: email
    }).then((response) => {
      dispatch(showMessage(response.data.message))
    }, error => {
      dispatch(showMessage(error.response.data.message))
    }).then(() => dispatch({type: 'UNBLOCK_UI'}))
  }
}
