import React from "react";
import Form from "react-bootstrap/Form";
import moment from "moment";

function generateYears() {
  const thisYear = moment().subtract(3, "months").year();
  const years = [];
  for (let y = 2010; y <= thisYear; y++) {
    years.push(y);
  }
  return years;
}

function ReportPeriod({
  reportType,
  periodDateFrom,
  setPeriodDateFrom,
  periodDateTo,
  setPeriodDateTo,
  periodMonthFrom,
  periodMonthTo,
  setPeriodMonthFrom,
  setPeriodMonthTo,
  periodYearFrom,
  periodYearTo,
  setPeriodYearFrom,
  setPeriodYearTo,
}) {
  const years = generateYears();

  if (reportType === "daily") {
    return (
      <>
        <div>期間</div>
        <Form.Group controlId="batch-report.report-period-from">
          <Form.Control
            type="date"
            name="dateFrom"
            value={periodDateFrom}
            onChange={(e) => setPeriodDateFrom(e.target.value)}
          />
        </Form.Group>
        ～
        <Form.Group controlId="batch-report.report-period-to">
          <Form.Control
            type="date"
            name="dateTo"
            value={periodDateTo}
            onChange={(e) => setPeriodDateTo(e.target.value)}
          />
        </Form.Group>
      </>
    );
  }

  if (reportType === "monthly") {
    return (
      <>
        <div>期間</div>
        <Form.Group controlId="batch-report.report-period">
          <Form.Control
            type="month"
            name="monthFrom"
            value={periodMonthFrom}
            onChange={(e) => setPeriodMonthFrom(e.target.value)}
          />
        </Form.Group>
        ～
        <Form.Group controlId="batch-report.report-period">
          <Form.Control
            type="month"
            name="monthTo"
            value={periodMonthTo}
            onChange={(e) => setPeriodMonthTo(e.target.value)}
          />
        </Form.Group>
      </>
    );
  }

  if (reportType === "annual") {
    return (
      <>
        <div>期間</div>
        <Form.Group controlId="batch-report.report-type">
          <Form.Control
            as="select"
            value={periodYearFrom}
            onChange={(e) => setPeriodYearFrom(e.target.value)}
          >
            {years.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        ～
        <Form.Group controlId="batch-report.report-type">
          <Form.Control
            as="select"
            value={periodYearTo}
            onChange={(e) => setPeriodYearTo(e.target.value)}
          >
            {years.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </>
    );
  }
}

export default ReportPeriod;
