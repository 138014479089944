export const DAILY_INDEX_PATH = '/daily'
export const MONTHLY_INDEX_PATH = '/monthly'
export const STATION_INDEX_PATH = '/stations'
export const NOTICE_INDEX_PATH = '/notices'
export const REALTIME_REPORT_INDEX_PATH = '/realtime_report'
export const YEARLY_INDEX_PATH = '/yearly'
export const SIGN_IN_PATH = '/users/login'
export const STATION_MONTHLY_REPORTS_INDEX_PATH = 'station_monthly_reports'
export const AIRPORT_MONTHLY_REPORTS_INDEX_PATH = 'airport_monthly_reports'
export const SAVE_SURVEY_ANSWER_PATH = '/surveys'
export const AIRPORTS_INDEX_PATH = '/airports'
export const SAVE_DOWNLOAD_CSV_PATH = '/csv_download_trackings'
export const FORGOT_PASSWORD_PATH = '/users/password_recoveries'
export const RESET_PASSWORD_PATH = '/users/password_recoveries/reset'
export const EXPORT_STATIONS_PATH = '/export_stations'
