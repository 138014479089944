import * as constants from './constants'
import { extend } from 'lodash'

const monthlyInitialState = {
  collection: [],
  tableHeadColumns: []
}

export function airportMonthlyReportReducer(state=monthlyInitialState, action) {
  switch (action.type) {
    case constants.GET_AIRPORT_MONTHLY_REPORT_SUCCESS:
      return action.payload
    default:
      return state
  }
}