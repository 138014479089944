import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Accordion, Card, Table } from 'react-bootstrap'
import { push } from 'connected-react-router'
import { cloneDeep, find } from 'lodash'

import { getYearlyData } from './actions'
import { getListOfStations } from '../home/actions'
import SignedInNav from 'components/signed_in_nav'
import Collapse from './components/collapses'
import moment from "moment"
import qs from 'query-string'
import ScrolltopButton from 'components/scrolltop_button'

import './styles.sass'

class Yearly extends Component {
  constructor(props) {
    super(props)
    this.reloadDataFor = this.reloadDataFor.bind(this)
    this.goToMonthlyPage = this.goToMonthlyPage.bind(this)
    this.changeCollapeOfYear = this.changeCollapeOfYear.bind(this)
    this.state = {
      activeKey: ''
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getListOfStations()

    const { router: { location: { query: { station_id } } } } = this.props
    let year = this.props.router.location.query.year

    if (!station_id) { return this.props.push({pathname: '/'}) }

    year = !!year ? year : moment().year()

    let query = { station_id: station_id, year: year }
    this.setState({ activeKey: `${year}` })
    this.props.getYearlyData(station_id, year)
    this.props.push({pathname: '/yearly', search: qs.stringify(query) })
  }

  reloadDataFor(stationId) {
    const { router: { location: { query: { year } } } } = this.props
    this.props.push(`/yearly?station_id=${stationId}`)
    this.props.getYearlyData(stationId, year)
  }

  goToMonthlyPage(month, year) {
    const { router: { location: { query: { station_id } } } } = this.props
    this.props.push(`/monthly?station_id=${station_id}&month=${month}&year=${year}`)
  }

  buildCsvData() {
    let csvData = []
    const yearlyData = cloneDeep(this.props.yearlyData)

    let data = find(yearlyData.collection, {year: this.state.activeKey})

    if (!!data) {
      csvData.push([data.collapseHeaderText]) // Append the year to each section
    }

    // Header of CSV
    let tableHeadColumns = cloneDeep(yearlyData.tableHeadColumns)
    tableHeadColumns.unshift('')
    csvData.push(tableHeadColumns)
    // finish render header for a section

    // Adding yearly data to csv
    if (!!data) {
      data.tableBodyRows.forEach((tableBodyRow) => {
        let row = Object.values(tableBodyRow.data)
        row.unshift(tableBodyRow.header)
        csvData.push(row)
      })
    }
    // Finished adding yearly data to csv

    return csvData
  }

  changeCollapeOfYear(year, eventKey) {
    const { router: { location: { query: { station_id } } } } = this.props

    this.setState({
      activeKey: eventKey
    })

    this.props.getYearlyData(station_id, year)
    let query = { station_id: station_id, year: year }
    this.props.push({pathname: '/yearly', search: qs.stringify(query) })
  }

  render() {
    const { activeKey } = this.state
    const { yearlyData, stationsData } = this.props
    const { router: { location: { query: { station_id, year } } } } = this.props
    const station = find(stationsData.stations, {stationid: station_id})

    let dayObject = new Date(year, 1, 1)

    return(
      <React.Fragment>
        <SignedInNav
          stationsData={stationsData}
          reloadDataFor={this.reloadDataFor}
          activeLink="yearly"
          stationId={station_id}
          csvData={this.buildCsvData()}
          fileName={`年報-${moment(dayObject).format('YYYY')}-${station_id}.csv`}
        />
        <div className="content yearly-wrapper">
          <div className="green-text-wrapper">
            <span className="green-text">
              {
                !!station && station.name
              }
            </span>
          </div>

          <div className="top-time-wrapper">
            <span className="top-time-text">
              年報
            </span>
          </div>

          <Accordion defaultActiveKey={activeKey} activeKey={activeKey}>
            <div className="collapse-wrapper">
              {
                yearlyData.collection.map((collapse, index) => {
                  return(<Collapse
                    collapseHeaderText={collapse.collapseHeaderText}
                    year={collapse.year}
                    eventKey={`${collapse.year}`}
                    key={index}
                    activeKey={activeKey}
                    goToMonthlyPage={this.goToMonthlyPage}
                    tableBodyRows={collapse.tableBodyRows}
                    tableHeadColumns={collapse.tableHeadColumns}
                    changeCollapeOfYear={this.changeCollapeOfYear}
                  />)
                })
              }
            </div>
          </Accordion>
        </div>
        <ScrolltopButton />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    yearlyData: state.yearlyData,
    router: state.router,
    stationsData: state.stationsData
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getListOfStations,
    getYearlyData,
    push,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Yearly)
