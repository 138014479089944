import * as constants from 'app/yearly/constants'
import { extend } from 'lodash'

const yearlyInitialState = {
  collection: [],
  tableHeadColumns: []
}

export function yearlyDataReducer(state=yearlyInitialState, action) {
  switch (action.type) {
    case constants.GET_YEARLY_DATA_SUCCESS:
      return action.payload
    default:
      return state
  }
}