import React, { Component } from 'react'
import classNames from 'classnames'
import Sound from 'components/stickies/sound'

class ContentRow extends Component {
  constructor(props) {
    super(props)
    this.renderColumn = this.renderColumn.bind(this)
  }

  renderColumn() {
    const { rowData } = this.props

    return(
      <React.Fragment>
        <td className="font-weight-normal lasmax"> {rowData.data['lasmax']} </td>
        <td className="font-weight-normal duration"> {rowData.data['duration']} </td>
        <td className="font-weight-normal bgnlevel"> {rowData.data['bgnlevel']} </td>
        <td className="font-weight-normal torl"> {rowData.data['torl']} </td>
        <td className="font-weight-normal id"> {rowData.data['id']} </td>
        <td className="font-weight-normal altitude"> {rowData.data['altitude']} </td>
        <td className="font-weight-normal lae"> {rowData.data['lae']} </td>
        <td className="font-weight-normal laeq"> {rowData.data['laeq']} </td>
        <td className="font-weight-normal estimation"> {rowData.data['estimation']} </td>
      </React.Fragment>
    )
  }

  render() {
    const { rowData, tableHeadColumns, rowIndex } = this.props

    return(
      <React.Fragment>
        <tr className={`${rowIndex % 2 !== 0 ? 'dark-row' : 'light-row'}`}
          onClick={() => {this.props.toggleSound(rowIndex)}}>
          {this.renderColumn()}
        </tr>
        {
          rowData.isShowingSound && rowData.data.wave && (
            <tr className={`${rowIndex % 2 !== 0 ? 'dark-row' : 'light-row'}`}>
              <td colSpan={tableHeadColumns.length} className="bg-white">
                <Sound id={`row-sound-${rowIndex}`} data={rowData.data} />
              </td>
            </tr>
          )
        }
      </React.Fragment>
    )
  }
}

export default ContentRow
