import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import moment from 'moment'

import Thead from './thead'
import ContentRow from './content_row'
import LeftSideHeader from './left_side_header'
import RightSideButtons from './right_side_buttons'

class YearlyTable extends Component {
  render() {
    const { tableHeadColumns, goToMonthlyPage, tableBodyRows, year } = this.props
    const is2YearsUntilNow = (parseInt(year) > moment().year() - 2)
    return(
      <React.Fragment>
        <div className="table-left-header">
          <LeftSideHeader tableBodyRows={tableBodyRows} />
        </div>

        <div className="table-content yearly" style={is2YearsUntilNow ? null : {width: '89%'}}>
          <Table hover size="sm" className="collapse-table-content">

            <thead>
              <Thead
                tableHeadColumns={tableHeadColumns}
                borderLeftClassNames="border-radius-top-left"
                borderRightClassNames="border-radius-top-right"
              />
            </thead>

            <tbody>
              {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
                <ContentRow
                  rowData={row}
                  rowIndex={index}
                  numberOfRows={tableBodyRows.length}
                  key={index}
                  tableHeadColumns={tableHeadColumns}
                  goToMonthlyPage={goToMonthlyPage}
                  year={row.year}
                  month={row.month}
                />
              )}
            </tbody>

            <tfoot>
              <Thead
                tableHeadColumns={tableHeadColumns}
                borderLeftClassNames="border-radius-bottom-left footer-row"
                borderRightClassNames="border-radius-bottom-right footer-row"
              />
            </tfoot>

          </Table>
        </div>

        {
          is2YearsUntilNow && (
            <div className="table-right-content">
              <RightSideButtons
                goToMonthlyPage={goToMonthlyPage}
                tableBodyRows={tableBodyRows}
                toggleSound={this.toggleSound}
              />
            </div>
          )
        }

      </React.Fragment>
    )
  }
}

export default YearlyTable
