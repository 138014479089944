import * as constants from 'app/daily/constants'
import axiosInstance from 'configurations/axios_instance'
import { DAILY_INDEX_PATH } from 'configurations/api_end_points'

export function getDailyData(day, year, month, stationId) {

  let params = {
    station_id: stationId
  }

  if (!!month) {
    params['year'] = year
  }

  if (!!month) {
    params['month'] = month
  }

  if (!!day) {
    params['day'] = day
  }

  return (dispatch) => {
    dispatch({type: 'BLOCK_UI'})

    axiosInstance.get(DAILY_INDEX_PATH, {
      params: params
    }).then(response => {
      dispatch({
        type: constants.GET_DAILY_DATA_SUCCESS,
        payload: response.data
      })
    }).catch().then(() => dispatch({type: 'UNBLOCK_UI'}))
  }

}