import React from "react";
import Form from "react-bootstrap/Form";

function ReportType({reportType, setReportType}) {

  return (
    <>
      <div>帳票種類</div>
      <Form.Group controlId="batch-report.report-type">
        <Form.Control as="select" value={reportType} onChange={(e) => setReportType(e.target.value)}>
          <option value='daily'>日報</option>
          <option value='monthly'>月報</option>
          <option value='annual'>年報</option>
        </Form.Control>
      </Form.Group>
    </>
  );
}

export default ReportType;
