import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'

import { sendResetPasswordInstructions } from 'app/forgot-password/actions'
import { push } from 'connected-react-router'
import Message from 'app/message'
import { hideMessage } from 'app/message/actions'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.sendResetPasswordInstructions = this.sendResetPasswordInstructions.bind(this)
    this.renderField = this.renderField.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.props.hideMessage()
  }

  sendResetPasswordInstructions(formValues){
    this.props.sendResetPasswordInstructions(formValues.email, formValues.password)
  }

  renderField(field) {
    return(
      <div>
        <Form.Control {...field.input} type={field.type} placeholder={field.placeholder}/>
        {field.meta.touched && field.meta.error && <span>{field.meta.error}</span>}
      </div>
    )
  }

  render() {
    const { handleSubmit, reset, submitting, message, isShow } = this.props

    return(
      <div>
        <div className="title text-center mb-2">
          <h3>パスワードをお忘れですか</h3>
        </div>

        <Form onSubmit={handleSubmit(this.sendResetPasswordInstructions)}>

          <Message message={ message } isShow={isShow}/>

          <Form.Group controlId="formBasicEmail" >
            <Form.Label>Eメール</Form.Label>
            <Field name="email" type="email" placeholder="Eメール" component={this.renderField}/>
          </Form.Group>

          <div className="d-flex justify-content-between">
            <Link
              className="align-self-center"
              to={{pathname: "/sign-in"}}
            >
              ログインする
            </Link>

            <Button variant="primary"
            type="submit"
            className="root-sea-btn btn-green align-self-center">
              参加する
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.message.message,
    isShow: state.message.isShow
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ sendResetPasswordInstructions, push, hideMessage }, dispatch)
}

ForgotPassword = reduxForm({form: 'signInForm'})(ForgotPassword)

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
