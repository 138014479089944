import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
// import cookie from "react-cookies";
import moment from "moment";

import { logoutUser } from "app/sign-in/actions";

class HeaderTop extends Component {
  render() {
    let hostname = window.location.hostname;
    const { currentUser, push } = this.props;

    return (
      <div className="header-top d-flex justify-content-start">
        <span className="align-self-center flex-grow-1">
          沖縄県 航空機騒音測定監視システム 監視情報
        </span>

        {process.env.REACT_APP_PRIVATE_URL.indexOf(hostname) > -1 &&
          (!!currentUser.authentication_token ? (
            <>

              <button
                className="align-self-center root-sea-btn btn-red btn-excel"
                onClick={() => push("/batch-report")}
              >
                Excel帳票ダウンロード
              </button>
              <button
                className="align-self-center root-sea-btn btn-green btn-logout"
                onClick={() => this.props.logoutUser()}
              >
                ログアウト
              </button>
            </>
          ) : (
            <>

              <button
                className="align-self-center root-sea-btn btn-red btn-excel"
                onClick={() => push("/batch-report")}
              >
                Excel帳票ダウンロード
              </button>
              <button
                className="align-self-center root-sea-btn btn-green btn-login"
                onClick={() => push("/sign-in")}
              >
                ログインする
              </button>
            </>
          ))}

        {process.env.REACT_APP_PUBLIC_URL.indexOf(hostname) > -1 && (
          <div className="data-last-update align-self-center">
            {`LAST UPDATE：${moment().format("YYYY年M月D日 HH:mm:ss")}`}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutUser,
      push,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);
