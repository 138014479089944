import React, { Component } from "react";
import { Table } from "react-bootstrap";

import ContentRow from "./content_row";
import Counter from "./counter";

class BatchReportTable extends Component {
  constructor(props) {
    super(props);
    this.onSelected = this.onSelected.bind(this);
    this.onHeaderSelected = this.onHeaderSelected.bind(this);
    this.countSelectedRows = this.countSelectedRows.bind(this);
    this.state = {
      /**
       * 測定局の選択状態を保持する state
       *
       * 各 stationid が 選択されている場合 true が入る
       * 非選択の場合は false でもいいし、key がなくてもよい
       *
       * ex)
       * checkedRows[FO01] : true
       */
      checkedRows: { FO01: false },
    };
  }

  /**
   * 各行の checkbox をクリックした場合
   * @param {SyntheticEvent} e
   * @param {string} stationid
   * @returns {null}
   */
  onSelected(e, stationid) {
    const checkedRows = this.state.checkedRows;
    const station_ids = this.props.stationIds
    checkedRows[stationid] = e.target.checked;
    this.setState({ checkedRows: checkedRows });

    // 各行の状態が変更された場合、on/off にかかわらず header の checkbox を解除する
    if ( e.target.checked === true) {
      this.props.setStationIds([...station_ids, stationid])
    } else {
      this.props.setStationIds(station_ids.filter(item => item !== stationid))
    }
    // 各行の状態が変更された場合、on/off にかかわらず header の checkbox を解除する
    document.getElementById("headerCheckbox").checked = false;
  }

  /**
   * Header の checkbox をクリックした場合、on=>全測定局選択 off=>全解除
   * @param {SyntheticEvent} e
   * @returns {null}
   */
  onHeaderSelected(e) {
    let station_ids = this.props.stationIds

    if (e.target.checked === true) {
      // 全測定局のリスト
      const { tableBodyRows } = this.props;

      // 各測定局の選択状態を保持する
      const tmp = {};

      // 全測定局をループして全て選択状態にする
      tableBodyRows.forEach((element) => {
        tmp[element.stationid] = true;
        station_ids = [...station_ids, element.stationid]
      });
      this.props.setStationIds(station_ids)
      // 全測定局を選択状態にする
      this.setState({ checkedRows: tmp });
    } else {
      // 測定局の選択を全て解除する
      this.props.setStationIds([])
      this.setState({ checkedRows: {} });
    }
  }

  /**
   * 選択されている測定局の数を返す
   * @returns {Number}
   */
  countSelectedRows() {
    let count = 0;

    for (const k in this.state.checkedRows) {
      if (this.state.checkedRows[k] === true) count++;
    }

    return count;
  }

  render() {
    const { tableBodyRows } = this.props;

    return (
      <div className="table-content batch-report">
        <div className="batch-report-header">測定局</div>

        <Counter
          selectedRows={this.countSelectedRows()}
          totalRows={tableBodyRows.length}
        />

        <Table hover size="sm" className="batch-report">
          <thead>
            <tr>
              <th className="checkbox">
                <input
                  id="headerCheckbox"
                  type="checkbox"
                  onChange={(e) => this.onHeaderSelected(e)}
                />
              </th>
              <td className="stationid">StationID</td>
              <td className="name">測定局名</td>
            </tr>
          </thead>

          <tbody>
            {!!tableBodyRows &&
              tableBodyRows.length > 0 &&
              tableBodyRows.map((row, index) => (
                <ContentRow
                  key={index}
                  rowData={row}
                  rowIndex={index}
                  checkedRows={this.state.checkedRows}
                  onSelected={this.onSelected}
                />
              ))}
          </tbody>
        </Table>

        <Counter
          selectedRows={this.countSelectedRows()}
          totalRows={tableBodyRows.length}
        />
      </div>
    );
  }
}

export default BatchReportTable;
