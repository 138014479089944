import React, { Component } from 'react'
import classNames from 'classnames'
import Sound from 'components/stickies/sound'

class ContentRow extends Component {
  constructor(props) {
    super(props)
    this.renderColumn = this.renderColumn.bind(this)
  }

  renderColumn(columnName, index) {
    const { rowData } = this.props

    return(
      <React.Fragment>
        <td className="font-weight-normal lasmax"> {rowData.data['lasmax']} </td>
        <td className="font-weight-normal duration"> {rowData.data['duration']} </td>
        <td className="font-weight-normal bgnlevel"> {rowData.data['bgnlevel']} </td>
        <td className="font-weight-normal lae"> {rowData.data['lae']} </td>
        <td className="font-weight-normal estimation"> {rowData.data['estimation']} </td>
      </React.Fragment>
    )
  }

  render() {
    const { rowData, tableHeadColumns, rowIndex } = this.props
    return(
      <React.Fragment>
        <tr className={`${rowIndex % 2 !== 0 ? 'dark-row' : 'light-row'}`} onClick={() => {this.props.toggleSound(rowIndex)}}>
          { this.renderColumn() }
        </tr>
        {
          rowData.isShowingSound && (
            <tr className={`${rowIndex % 2 !== 0 ? 'dark-row' : 'light-row'}`}>
              <td colSpan={tableHeadColumns.length} className="bg-white">
                <Sound
                  id={`row-sound-${rowIndex}`}
                  data={rowData.data}
                  description='※音声と画像はサンプルです。'/>
              </td>
            </tr>
          )
        }
      </React.Fragment>
    )
  }
}

export default ContentRow
