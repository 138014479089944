import { combineReducers } from 'redux'
import { i18nReducer } from 'react-redux-i18n'
import { reducer as formReducer } from 'redux-form'
import { currentUserReducer } from 'app/sign-in/reducers'
import { dailyDataReducer } from 'app/daily/reducers'
import { yearlyDataReducer } from 'app/yearly/reducers'
import { monthlyDataReducer } from 'app/monthly/reducers'
import { stationMonthlyReportReducer } from 'app/station_monthly_report/reducers'
import { airportMonthlyReportReducer } from 'app/airport_monthly_report/reducers'
import {
  stationsDataReducer,
  noticeDataReducer,
  airportsDataReducer,
  savedSurveyReducer,
} from 'app/home/reducers'
import { connectRouter } from 'connected-react-router'
import { stationRealtimeReportReducer } from 'app/realtime_report/reducers'
import { savedDownloadCsvLocationReducer } from 'components/download_csv_location/reducers'
import { messageReducer } from 'app/message/reducers'
import { blockUIReducer } from 'app/block-ui/reducers'
import { popupPicturesReducer, openedModalsReducer } from 'app/image-modals/reducers'


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  currentUser: currentUserReducer,
  dailyData: dailyDataReducer,
  yearlyData: yearlyDataReducer,
  monthlyData: monthlyDataReducer,
  stationsData: stationsDataReducer,
  form: formReducer,
  stationMonthlyReport: stationMonthlyReportReducer,
  airportMonthlyReportData: airportMonthlyReportReducer,
  i18n: i18nReducer,
  notificationData: noticeDataReducer,
  airportsData: airportsDataReducer,
  realtimeReportData: stationRealtimeReportReducer,
  savedSurvey: savedSurveyReducer,
  savedDownloadCsv: savedDownloadCsvLocationReducer,
  message: messageReducer,
  isBlockUI: blockUIReducer,
  popupPictures: popupPicturesReducer,
  openedModals: openedModalsReducer
})

export default createRootReducer