import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import Marker from './marker'
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Map extends Component {

  static defaultProps = {
    center: {
      lat: 26.26997786134668,
      lng: 127.7691860611328
    }
  };

  constructor(props) {
    super(props)
    this.state = {
      allMarkersEnabled: false,
      mapZoom: 11
    }

    this.toggleAllMarkers = this.toggleAllMarkers.bind(this)
    this.onZoomAnimationEnd = this.onZoomAnimationEnd.bind(this)
    this.onZoomAnimationStart = this.onZoomAnimationStart.bind(this)
    this.checkInBound = this.checkInBound.bind(this)
  }

  toggleAllMarkers(status) {
    this.setState({allMarkersEnabled: status})
  }

  onZoomAnimationEnd(mapZoom) {
    this.toggleAllMarkers(true)
    this.setState({mapZoom: mapZoom})
  }

  onZoomAnimationStart() {
    this.toggleAllMarkers(false)
  }

  checkInBound(marker) {
    // if there is no data, then return false to avoid showing pop hover on marker
    if (!this.mapRef || !this.mapRef.map_ || !this.mapRef.map_.getBounds() || !marker.longitude || !marker.latitude){
      return false
    }

    const isInbound = this.mapRef.map_.getBounds().contains(
      {
        lat: parseFloat(marker.longitude),
        lng: parseFloat(marker.latitude)
      }
    )

    return isInbound
  }

  render() {
    const {
      center, zoom, currentUser, loggedIn,
      stationsData: { stations },
      airportsData: { airports }
    } = this.props

    const { mapZoom } = this.state

    const { allMarkersEnabled } = this.state

    return (
      <div className="root-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyA1IbkwYCpyeVRnvI2MA4sm2tW63Yfm-fU' }}
          defaultCenter={center}
          ref={(map) => {this.mapRef = map}}
          defaultZoom={mapZoom}
          onZoomAnimationStart={this.onZoomAnimationStart}
          onZoomAnimationEnd={this.onZoomAnimationEnd}
          onDrag={() => this.toggleAllMarkers(false)}
          onDragEnd={() => { this.toggleAllMarkers(true)}}
        >

          {stations.map((marker, index) => {
            return(
              <Marker
                isInBound={this.checkInBound(marker)}
                lat={marker.longitude}
                lng={marker.latitude}
                currentUser={currentUser}
                marker={marker}
                key={index}
                activeKey = {marker.stationid}
                loggedIn={loggedIn}
                allMarkersEnabled={allMarkersEnabled}
                toggleAllMarkers={this.toggleAllMarkers}
                mapZoom={mapZoom}
              />
            )
          })}

          {!loggedIn && airports.map((marker, index) => {
            return(
              <Marker
                isInBound={this.checkInBound(marker)}
                lat={marker.longitude}
                lng={marker.latitude}
                currentUser={currentUser}
                marker={marker}
                key={index}
                loggedIn={loggedIn}
                allMarkersEnabled={allMarkersEnabled}
                toggleAllMarkers={this.toggleAllMarkers}
                mapZoom={mapZoom}
              />
            )
          })}

        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;

