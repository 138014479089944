import React, { Component } from 'react'
import ScrolltopButton from '../scrolltop_button'
import moment from "moment"
import './styles.sass'

class Pagination extends Component {
  constructor(props) {
    super(props)
    this.getNextBtnText = this.getNextBtnText.bind(this)
    this.getPrevBtnText = this.getPrevBtnText.bind(this)
    this.isFirstPage = this.isFirstPage.bind(this)
  }

  getNextBtnText() {
    const { page } = this.props
    if (page === "daily") {
      return "daily"
    } else {
      return "2019年12月の日報へ"
    }
  }

  getPrevBtnText(){
    const { page } = this.props
    if (page === "daily") {
      return "daily"
    } else {
      return "2019年12月の日報へ"
    }
  }

  isFirstPage() {
    return true
  }

  isLastPage() {
    return false
  }

  render() {
    const {
      nextPageEnabled, prevPageEnabled, prevPageText,
      nextPageText, onGoToPrevPage, onGoToNextPage
    } = this.props

    return(
      <React.Fragment>
        <div className="d-flex justify-content-center pagination">
          {
            nextPageEnabled && (
              <button className="btn btn-success mr-2" onClick={onGoToNextPage}>
                <span className="left-icon">
                  <img src={require('assets/images/pagination-left-icon.png')}/>
                </span>
                <span className='left-button-text'>{nextPageText}</span>
              </button>
            )
          }

          {
            prevPageEnabled && (
              <button className="btn btn-success ml-2" onClick={onGoToPrevPage}>
                <span className='right-button-text'>{prevPageText}</span>
                <span className="right-icon">
                  <img src={require('assets/images/pagination-right-icon.png')}/>
                </span>
              </button>
            )
          }

        </div>

        <ScrolltopButton />
      </React.Fragment>
    )
  }
}

export default Pagination
