import * as constants from 'app/monthly/constants'
import { extend, cloneDeep } from 'lodash'

export function popupPicturesReducer(state=[], action) {
  switch (action.type) {
    case 'SET_POPUP_PICTURES':
      return action.payload
    default:
      return state
  }
}

export function openedModalsReducer(state=[], action) {
  switch (action.type) {
    case 'SET_OPENED_IMAGE_MODAL':
      return cloneDeep(action.payload)
    default:
      return state
  }
}
