import React, { Component } from 'react'
import { Table, Button } from 'react-bootstrap'

class RightSideButtons extends Component {
  render() {
    const { tableBodyRows, toggleSound } = this.props

    return(
      <Table hover size="sm" className="collapse-table-right-side">
        <tbody>
          <tr><th className="invisible">.</th></tr>
          {(!!tableBodyRows && tableBodyRows.length > 0) && tableBodyRows.map((row, index) =>
            <React.Fragment key={index}>
              <tr key={index} className={`${index % 2 !== 0 ? 'dark-row' : 'light-row'}`}>
                <td className={index===0 ? 'border-radius-top-right' : ''}>
                  <Button className="btn-show-playlist" onClick={() => toggleSound(index)}>
                    詳細
                    <span className={`${row.isShowingSound ? 'arrow-down' : 'arrow-up'} btn-arrow`}> </span>
                  </Button>
                </td>
              </tr>

              {
                row.isShowingSound && (
                  <tr key={`${index}_1`} className={`${index % 2 !== 0 ? 'dark-row' : 'light-row'} extra-row`}>
                    <td className="col-border-top1-transparent">
                      <div style={{height: `${row.elementHeightOnSoundVisible}px`}} />
                    </td>
                  </tr>
                )
              }
            </React.Fragment>
          )}
        </tbody>
      </Table>
    )
  }
}

export default RightSideButtons

