import React, { Component } from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'

import Table from '../tables'

class Collapse extends Component {
  constructor(props) {
    super(props)
    this.renderTable = this.renderTable.bind(this)
  }

  renderTable() {
    const { tableHeadColumns, tableBodyRows } = this.props

    return(
      <Table
        tableBodyRows={tableBodyRows}
        tableHeadColumns={tableHeadColumns}
      />
    )
  }

  renderEmptyMessage() {
    const { emptyMessage } = this.props

    return(
      <div className="text-center no-data-text panel-small-text">
        {!!emptyMessage ? emptyMessage : 'データはありません'}
      </div>
    )
  }

  render() {
    const { collapseHeaderText, eventKey, tableHeadColumns, getRealtimeReportData, lastUpdatedAt} = this.props

    return(
      <div className="accordion">
        <div className="collapse-wrapper">
          <Card.Header className="collapse-header">
            {collapseHeaderText}
          </Card.Header>

          <div className="collapse show">
            {
              (!!tableHeadColumns && tableHeadColumns.length > 0) ?

              (

                <React.Fragment>
                  <div className="d-flex pb-3 panel-small-text height50 justify-content-end align-items-center">
                    <span>LAST UPDATE：{lastUpdatedAt}</span>
                    <Button variant="" className="btn-panel-small root-sea-btn-small ml-2" onClick={getRealtimeReportData}>
                      <span className="refresh-icon btn-arrow"></span>
                      更新
                    </Button>
                  </div>

                  {this.renderTable()}
                </React.Fragment>

              ) : this.renderEmptyMessage()
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Collapse
