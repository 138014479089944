import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { cloneDeep, find } from 'lodash'
import moment from 'moment'

import ScrolltopButton from 'components/scrolltop_button'
import { getRealtimeReportData } from './actions'
import { getListOfStations } from '../home/actions'
import SignedInNav from 'components/signed_in_nav'
import Collapse from './components/collapses'
import ImageModals from 'app/image-modals'
import './styles.sass'

class RealtimeReport extends Component {
  constructor(props) {
    super(props)
    this.reloadDataFor = this.reloadDataFor.bind(this)
    this.buildCsvData = this.buildCsvData.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.props.getListOfStations()

    const { router: { location: { query: { station_id } } } } = this.props

    if (!station_id) { return this.props.push({pathname: '/'}) }

    if (!!station_id) {
      this.props.getRealtimeReportData(station_id)
    }
  }

  reloadDataFor(stationId) {
    this.props.push(`/realtime_report?station_id=${stationId}`)
    this.props.getRealtimeReportData(stationId)
  }

  buildCsvData() {
    let csvData = []
    const realtimeReportData = cloneDeep(this.props.realtimeReportData)

    realtimeReportData.forEach((realtimeReport, index) => {
      if (index === 0) {
        let tableHeadColumns = realtimeReport.tableHeadColumns
        tableHeadColumns.unshift('')
        csvData.push(tableHeadColumns)
      }

      let row = []
      realtimeReport.tableBodyRows.forEach((tableBodyRow, index) => {
        row = Object.values(tableBodyRow.data)
        row.unshift(tableBodyRow.header)
      })
      csvData.push(row)
    })

    return csvData
  }

  render() {
    const { realtimeReportData, stationsData, getRealtimeReportData } = this.props
    const { router: { location: { query: { station_id } } } } = this.props
    const station = find(stationsData.stations, {stationid: station_id})

    return(
      <React.Fragment>
        <SignedInNav
          stationsData={stationsData}
          reloadDataFor={this.reloadDataFor}
          activeLink="realtime"
          stationId={station_id}
          csvData={this.buildCsvData()}
          fileName={`最新騒音値-${moment().format('YYYYMMDD HH:MM')}-${station_id}.csv`}
        />
        <div className="content realtime-report-wrapper">
          <div className="green-text-wrapper">
            <span className="green-text">
              {
                !!station && station.name
              }
            </span>
          </div>

          <div className="top-time-wrapper">
            <span className="top-time-text">
              最新騒音値
            </span>
          </div>

          {
            realtimeReportData.map((collapse, index) => {
              return(<Collapse
                collapseHeaderText={collapse.collapseHeaderText}
                lastUpdatedAt={collapse.lastUpdatedAt}
                eventKey={`${index}`}
                key={index}
                tableBodyRows={collapse.tableBodyRows}
                tableHeadColumns={collapse.tableHeadColumns}
                getRealtimeReportData={() => getRealtimeReportData(station_id)}
                includeSticky={true}
              />)
            })
          }

        </div>
        <ScrolltopButton />
        <ImageModals />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    router: state.router,
    stationsData: state.stationsData,
    realtimeReportData: state.realtimeReportData
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getListOfStations,
    getRealtimeReportData,
    push
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RealtimeReport)
