import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import qs from 'query-string'

class Filter extends Component {
  constructor(props) {
    super(props)
    this.renderFilterForNonLoginPage = this.renderFilterForNonLoginPage.bind(this)
    this.renderFilterForLoggedInPage = this.renderFilterForLoggedInPage.bind(this)
    this.goToReportPage = this.goToReportPage.bind(this)
    this.goToMonthlyPage = this.goToMonthlyPage.bind(this)
    this.state = {
      selectedAirportStation: '', // This is used for none login user
      selectedStation: '' // This is used for logged in user
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.airportsData.airports.length > 0) {
      const airport = nextProps.airportsData.airports[0]
      const selectedAirportStation = `airport/${airport.icao}`

      this.setState({selectedAirportStation: selectedAirportStation})
    }

    if (nextProps.stationsData.stations.length > 0) {
      const station = nextProps.stationsData.stations[0]
      this.setState({selectedStation: station.stationid})
    }
  }

  goToReportPage() {
    const itemId = this.state.selectedAirportStation.split('/').pop()
    if (this.state.selectedAirportStation.indexOf('airport') > -1) {
      this.props.push({pathname: '/airport_monthly_report', search: qs.stringify({ airport_id: itemId}) })
    } else if (this.state.selectedAirportStation.indexOf('station') > -1) {
      this.props.push({pathname: '/station_monthly_report', search: qs.stringify({ station_id: itemId}) })
    } else {
      this.props.push({pathname: '/monthly', search: qs.stringify({ station_id: itemId}) })
    }
  }

  goToMonthlyPage() {
    this.props.push({pathname: '/monthly', search: qs.stringify({ station_id: this.state.selectedStation}) })
  }

  renderFilterForNonLoginPage() {
    const { airportsData, stationsData } = this.props

    return(
      <form className="filter-map d-flex">
        <div className="station-search-text d-inline-flex">
          測定局・
          <br/>
          飛行場検索
        </div>

        <select
          className="form-control station-search-dropdown d-inline-flex"
          onChange={(e) => this.setState({selectedAirportStation: e.target.value})}
          value={this.state.selectedAirportStation}
        >
          {
            !!airportsData.airports && airportsData.airports.map((airport, index) => {
              return(
                <option
                  value={`airport/${airport.icao}`}
                  key={index}
                >
                  {airport.name}
                </option>
              )
            })
          }
          {
            !!stationsData.stations && stationsData.stations.map((station, index) => {
              return(
                <option
                  value={`station/${station.stationid}`}
                  key={index}
                >
                  {station.name}
                </option>
              )
            })
          }
        </select>
        <button type="button" className="station-search-button" onClick={this.goToReportPage}>
          測定結果を表示する
        </button>
      </form>

    )
  }

  renderFilterForLoggedInPage() {
    const { stationsData } = this.props

    return(
      <form className="filter-map d-flex align-items-center justify-content-center">
        <span className="station-search-text d-inline-flex">測定局検索</span>
        <select
          className="form-control station-search-dropdown d-inline-flex"
          onChange={(e) => this.setState({selectedStation: e.target.value})}
          value={this.state.selectedStation}
        >
          {
            !!stationsData.stations && stationsData.stations.map((station, index) => {
              return(
                <option
                  value={`${station.stationid}`}
                  key={index}
                >
                  {station.name}
                </option>
              )
            })
          }
        </select>
        <button type="button" className="station-search-button" onClick={this.goToMonthlyPage}>
          月報を表示する
        </button>
      </form>
    )
  }

  render() {
    const { currentUser, loggedIn } = this.props

    if (loggedIn) {
      return this.renderFilterForLoggedInPage()
    } else {
      return this.renderFilterForNonLoginPage()
    }
  }
}

export default connect(null, { push })(Filter)